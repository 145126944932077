import React, { Component, Fragment } from "react";

import Footer from "../../components/Footer";
import StoreBlock1 from "../../components/Store/Block1";
import Packages from "../../components/Store/Packages";

export default class Store extends Component {
	render() {
		const { plans, packages, language } = this.props;
		return (
			<Fragment>
				<div className="w-100 h-100" style={{ paddingTop: 64 }}>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<StoreBlock1 language={language} />
					</div>
					<div className="w-100 py-5">
						<Packages plans={plans} packages={packages} asPlans corporateConfigs={{ color: "#00A3E0" }} language={language} onBuy={() => window.open(`${window.configs.appUrl}/signIn`, "_blank")} />
					</div>
					<Footer from="overview" language={language} />
				</div>
			</Fragment>
		);
	}
}
