import React, { Component } from "react";

import { Slide } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_safeandfast.0e6ee217.png";

export default class OverviewBlock4 extends Component {
	state = {
		img1In: false,
	};

	render() {
		const { img1In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row">
						<div className="col-12 col-lg-4 d-flex order-1 order-lg-2 d-flex align-items-center">
							<div className="text-start">
								<h2 className="mb-4" style={{ color: "#00A3E0" }}>
									SIGN FROM ANYWHERE
								</h2>
								<p className="mb-4">Our mobile apps for iOS, Android, and Windows let you work on the go, even when you don’t have internet access.</p>
								<p className="mb-4">You can even ask for your individual certificate in SigningDesk platform, and use it immediately after a fast and secure validation process.</p>
							</div>
						</div>
						<div className="col-12 col-lg-8 order-2 order-lg-1 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
							{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
							<Slide direction="up" in={img1In} timeout={1000}>
								<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
							</Slide>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row">
					<div className="col-12 col-lg-4 d-flex order-1 order-lg-2 d-flex align-items-center">
						<div className="text-start">
							<h2 className="mb-4" style={{ color: "#00A3E0" }}>
								ASSINE EM QUALQUER LUGAR
							</h2>
							<p className="mb-4">As nossas aplicações móveis para iOS, Android e Windows permitem trabalhar em qualquer lugar, mesmo quando não tem acesso à Internet.</p>
							<p className="mb-4">Pode mesmo solicitar o seu certificado individual na plataforma SigningDesk e usá-lo imediatamente após um processo de validação rápido e seguro.</p>
						</div>
					</div>
					<div className="col-12 col-lg-8 order-2 order-lg-1 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
						{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
						<Slide direction="up" in={img1In} timeout={1000}>
							<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
						</Slide>
					</div>
				</div>
			</div>
		);
	}
}
