import React, { Component } from "react";

import { Button, Typography } from "@mui/material";

export default class HomeBlock2 extends Component {
	render() {
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="w-100 container text-center my-5">
					<h4 className="mb-3">The electronic signature platform chosen by the European Commission, Multinationals and SME's</h4>
					<Typography className="mb-3">
						Remote work has become a reality and DigitalSign has the solution to promote the signing of documents online. Easily sign and send a document to be digitally signed, securely, 100% online, with just a few clicks.
					</Typography>
					<Button style={{ textTransform: "none" }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
						Start Now For Free!
					</Button>
				</div>
			);
		}
		return (
			<div className="w-100 container text-center my-5">
				<h4 className="mb-3">A plataforma de assinatura eletrónica escolhida pela Comissão Europeia, Multinacionais e PME's</h4>
				<Typography className="mb-3">
					O trabalho remoto passou a ser uma realidade e a DigitalSign tem a solução para facilitar a assinatura de documentos à distância. Assine e envie facilmente um documento para ser assinado digitalmente, de forma segura, 100% online,
					com apenas alguns cliques.
				</Typography>
				<Button style={{ textTransform: "none" }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
					Começa Já Gratuitamente!
				</Button>
			</div>
		);
	}
}
