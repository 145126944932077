import React, { Component, Fragment } from "react";

import FeaturesBlock1 from "../../components/Features/Block1";
import FeaturesBlock2 from "../../components/Features/Block2";
import FeaturesBlock3 from "../../components/Features/Block3";
import Footer from "../../components/Footer";

export default class Features extends Component {
	render() {
		const { language } = this.props;
		return (
			<Fragment>
				<div className="w-100 h-100" style={{ paddingTop: 64 }}>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<FeaturesBlock1 language={language} />
					</div>
					<div className="w-100 py-5">
						<FeaturesBlock2 language={language} />
					</div>
					<div className="w-100" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<FeaturesBlock3 language={language} />
					</div>
					<Footer from="overview" language={language} />
				</div>
			</Fragment>
		);
	}
}
