import React, { Component } from "react";

import { Drawer } from "@mui/material";

import Content from "./Content";

export default class StartForFreeDrawer extends Component {
	state = {
		closed: false,
	};

	render() {
		const { closed } = this.state;
		const { show, language } = this.props;
		return (
			<Drawer open={!closed && show} anchor="bottom" variant="persistent" PaperProps={{ style: { border: 0 } }} transitionDuration={500} SlideProps={{ mountOnEnter: true, unmountOnExit: true, appear: true }}>
				<Content language={language} onClose={() => this.setState({ closed: true })} />
			</Drawer>
		);
	}
}
