import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

import { NotificationContainer } from "react-notifications";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment-duration-format";

import Pages from "./Pages";

export default class App extends Component {
	render() {
		return (
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<BrowserRouter>
					<NotificationContainer />
					<Pages />
				</BrowserRouter>
			</LocalizationProvider>
		);
	}
}
