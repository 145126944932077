import React, { Component } from "react";

import { Slide } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_realtimestatus.b33911b1.png";

export default class OverviewBlock3 extends Component {
	state = {
		img1In: false,
	};

	render() {
		const { img1In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row">
						<div className="col-12 col-lg-4 d-flex d-flex align-items-center">
							<div className="text-start">
								<h2 className="mb-4" style={{ color: "#00A3E0" }}>
									REUSABLE TEMPLATES
								</h2>
								<p className="mb-4">Save time and standardize processes by storing frequently used workflows and templates along with custom fields, recipient routing, and other settings.</p>
							</div>
						</div>
						<div className="col-12 col-lg-8 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
							{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
							<Slide direction="up" in={img1In} timeout={1000}>
								<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
							</Slide>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row">
					<div className="col-12 col-lg-4 d-flex d-flex align-items-center">
						<div className="text-start">
							<h2 className="mb-4" style={{ color: "#00A3E0" }}>
								TEMPLATES REUTILIZÁVEIS
							</h2>
							<p className="mb-4">
								Economize tempo e padronize processos guardando fluxos de trabalho e templates utilizados com mais frequência, juntamente com campos personalizados, rastreabilidade de destinatários e outras configurações.
							</p>
						</div>
					</div>
					<div className="col-12 col-lg-8 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
						{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
						<Slide direction="up" in={img1In} timeout={1000}>
							<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
						</Slide>
					</div>
				</div>
			</div>
		);
	}
}
