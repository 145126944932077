import React, { Component } from "react";

import YouTube from "react-youtube";

export default class HomeBlock4 extends Component {
	state = {
		screen: window.innerWidth >= 560 ? "large" : window.innerWidth >= 350 ? "medium" : "small",
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		if (window.innerWidth >= 560) {
			if (this.state.screen !== "large") {
				this.setState({ screen: "large" });
			}
		} else if (window.innerWidth >= 350) {
			if (this.state.screen !== "medium") {
				this.setState({ screen: "medium" });
			}
		} else {
			if (this.state.screen !== "small") {
				this.setState({ screen: "small" });
			}
		}
	};

	screenToPx = {
		large: 32,
		medium: 28,
		small: 24,
	};

	render() {
		const { screen } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row">
						<div className="col-12 col-lg-6 d-flex align-items-center">
							<div className="text-start">
								<h2 className="mb-4 position-relative" style={{ fontSize: this.screenToPx[screen] }}>
									<div style={{ position: "absolute", top: -16, left: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>
									The solution adopted by several multinationals and SME's
								</h2>
								<p className="mb-4">
									See in this video how <b>EDP Group</b> found in the SigningDesk the solution for digitizing their processes.
								</p>
								<p className="mb-4">
									Ensure the trust and security of your documents and adhere to <b>qualified electronic signatures</b>, the only equivalent to handwritten signatures and with full legal value.
								</p>
								<p className="">For less critical documents we also offer simple and advanced signatures, ending with manual tasks and promoting remote work.</p>
							</div>
						</div>
						<div className="col-12 col-lg-6 d-flex">
							<YouTube containerClassName="w-100 m-auto" videoId="xM9Ax9t8808" opts={{ width: "100%" }} />
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row">
					<div className="col-12 col-lg-6 d-flex align-items-center">
						<div className="text-start">
							<h2 className="mb-4 position-relative" style={{ fontSize: this.screenToPx[screen] }}>
								<div style={{ position: "absolute", top: -16, left: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>A SOLUÇÃO ADOTADA POR DIVERSAS MULTINACIONAIS E PME'S
							</h2>
							<p className="mb-4">
								Veja neste vídeo como o <b>grupo EDP</b> encontrou no SigningDesk a solução ideal para a digitalização dos seus processos.
							</p>
							<p className="mb-4">
								Garanta a confiança e segurança dos seus documentos e adira às <b>assinaturas eletrónicas qualificadas</b>, as únicas equivalentes às assinaturas manuscritas e com total valor legal.
							</p>
							<p className="">Para documentos menos críticos oferecemos também assinaturas simples e avançadas, eliminado as tarefas manuais e facilitando o trabalho remoto.</p>
						</div>
					</div>
					<div className="col-12 col-lg-6 d-flex">
						<YouTube containerClassName="w-100 m-auto" videoId="xM9Ax9t8808" opts={{ width: "100%" }} />
					</div>
				</div>
			</div>
		);
	}
}
