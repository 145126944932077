import React, { Component } from "react";

export default class StoreBlock1 extends Component {
	render() {
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="w-100 container text-center pt-2" style={{ color: "#33647E" }}>
					<h1 className="mb-4 fw-bold">SigningDesk</h1>
					<h4>
						<a href={`${window.configs.appUrl}/signUp`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
							Signup
						</a>{" "}
						and try out 25 free signatures. Start signing documents online today, anywhere, at any time. Do you need more signatures?{" "}
						<a href={`${window.configs.appUrl}/signIn`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
							Log into your account
						</a>{" "}
						to purchase more!
					</h4>
				</div>
			);
		}
		return (
			<div className="w-100 container text-center pt-2" style={{ color: "#33647E" }}>
				<h1 className="mb-4 fw-bold">SigningDesk</h1>
				<h4>
					<a href={`${window.configs.appUrl}/signUp`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
						Registe-se
					</a>{" "}
					e experimente 25 assinaturas grátis. Começa hoje a assinar documentos online, a qualquer hora e em qualquer lugar. Precisa de mais assinaturas?{" "}
					<a href={`${window.configs.appUrl}/signIn`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
						Entre na sua conta
					</a>{" "}
					e adquira mais!
				</h4>
			</div>
		);
	}
}
