import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { AppBar, Toolbar, Button, IconButton, Icon, Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";

import LanguageMenu from "./LanguageMenu";

import Logo from "../../assets/logos/Logo_DS_SigningDesk_cores_desktop.svg";
import Buttons from "./Buttons";

import dictionary from "./index.dictionary.json";

export default class MainAppBar extends Component {
	state = {
		bigScreen: window.innerWidth >= 1200,
		expanded: false,
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		if (window.innerWidth >= 1200) {
			if (!this.state.bigScreen) {
				this.setState({ bigScreen: true });
			}
		} else {
			if (this.state.bigScreen) {
				this.setState({ bigScreen: false });
			}
		}
	};

	closeDrawer = () => {
		this.setState({ expanded: false });
	};

	render() {
		const { bigScreen, expanded } = this.state;
		const { language, transparent } = this.props;
		if (bigScreen) {
			return (
				<div>
					<AppBar elevation={transparent ? 0 : 15} style={{ backgroundColor: transparent ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 1)", transition: "all 500ms" }}>
						<Toolbar style={{ transition: "all 500ms", ...(transparent ? { height: 96, color: "white" } : { height: 64, color: "#1d252d" }) }}>
							<NavLink to="/home" exact>
								<img src={Logo} alt="logo" style={{ filter: transparent ? "brightness(0) invert(1)" : undefined, transition: "all 500ms" }} />
							</NavLink>
							<div className="flex-grow-1"></div>
							<Button color="inherit" component={NavLink} to="/overview" exact className="DisabelHoverLink mx-3" style={{ transition: "all 100ms", fontWeight: "unset" }} activeStyle={{ borderBottom: "solid 5px #00A3E0" }}>
								{dictionary.overview[language]}
							</Button>
							<Button color="inherit" component={NavLink} to="/features" exact className="DisabelHoverLink mx-3" style={{ transition: "all 100ms", fontWeight: "unset" }} activeStyle={{ borderBottom: "solid 5px #00A3E0" }}>
								{dictionary.features[language]}
							</Button>
							<Button color="inherit" component={NavLink} to="/benefits" exact className="DisabelHoverLink mx-3" style={{ transition: "all 100ms", fontWeight: "unset" }} activeStyle={{ borderBottom: "solid 5px #00A3E0" }}>
								{dictionary.benefits[language]}
							</Button>
							<Button color="inherit" component={NavLink} to="/store" exact className="DisabelHoverLink mx-3" style={{ transition: "all 100ms", fontWeight: "unset" }} activeStyle={{ borderBottom: "solid 5px #00A3E0" }}>
								{dictionary.store[language]}
							</Button>
							<Buttons language={language} bigScreen={bigScreen} transparent={transparent} onOpenContactForm={this.props.onOpenContactForm} />
							<LanguageMenu language={language} onUpdateLanguage={this.props.onUpdateLanguage} />
						</Toolbar>
					</AppBar>
				</div>
			);
		}
		return (
			<div>
				<AppBar elevation={transparent ? 0 : 15} style={{ backgroundColor: transparent ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 1)", transition: "all 500ms" }}>
					<Toolbar style={{ transition: "all 500ms", ...(transparent ? { height: 96, color: "white" } : { height: 64, color: "#1d252d" }) }}>
						<NavLink to="/home" exact>
							<img src={Logo} alt="logo" style={{ filter: transparent ? "brightness(0) invert(1)" : undefined, transition: "all 500ms" }} />
						</NavLink>
						<div className="flex-grow-1"></div>
						<IconButton size="large" color="inherit" style={{ color: transparent ? undefined : "#00A3E0" }} onClick={() => this.setState({ expanded: !expanded })}>
							<Icon fontSize="large">more_vert</Icon>
						</IconButton>
					</Toolbar>
					<Drawer anchor="top" open={expanded} onClose={this.closeDrawer}>
						<AppBar position="relative" elevation={transparent ? 0 : 15} style={{ backgroundColor: transparent ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 1)", transition: "all 500ms" }}>
							<List className="py-0 bg-white text-dark" onKeyDown={this.closeDrawer}>
								<ListItem button component={NavLink} to="/overview" exact style={{ transition: "all 500ms" }} activeStyle={{ backgroundColor: "#00A3E0" }} activeClassName="text-white" onClick={this.closeDrawer}>
									<ListItemText primary={dictionary.overview[language]} className="text-center" />
								</ListItem>
								<Divider />
								<ListItem button component={NavLink} to="/features" exact style={{ transition: "all 500ms" }} activeStyle={{ backgroundColor: "#00A3E0" }} activeClassName="text-white" onClick={this.closeDrawer}>
									<ListItemText primary={dictionary.features[language]} className="text-center" />
								</ListItem>
								<Divider />
								<ListItem button component={NavLink} to="/benefits" exact style={{ transition: "all 500ms" }} activeStyle={{ backgroundColor: "#00A3E0" }} activeClassName="text-white" onClick={this.closeDrawer}>
									<ListItemText primary={dictionary.benefits[language]} className="text-center" />
								</ListItem>
								<Divider />
								<ListItem button component={NavLink} to="/store" exact style={{ transition: "all 500ms" }} activeStyle={{ backgroundColor: "#00A3E0" }} activeClassName="text-white" onClick={this.closeDrawer}>
									<ListItemText primary={dictionary.store[language]} className="text-center" />
								</ListItem>
								<Divider />
								<ListItem className="w-100 d-flex justify-content-center flex-wrap">
									<Buttons language={language} onOpenContactForm={this.props.onOpenContactForm} />
								</ListItem>
								<Divider />
								<ListItem className="w-100 d-flex justify-content-center">
									<LanguageMenu language={language} onUpdateLanguage={this.props.onUpdateLanguage} />
								</ListItem>
								<Divider />
								<Divider />
							</List>
							<Toolbar style={{ height: 64, color: "#1d252d" }}>
								<NavLink to="/home" exact onClick={this.closeDrawer}>
									<img src={Logo} alt="logo" />
								</NavLink>
								<div className="flex-grow-1"></div>
								<IconButton size="large" color="inherit" style={{ color: "#00A3E0" }} onClick={() => this.setState({ expanded: !expanded })}>
									<Icon fontSize="large">more_vert</Icon>
								</IconButton>
							</Toolbar>
						</AppBar>
					</Drawer>
				</AppBar>
			</div>
		);
	}
}
