import React, { Component } from "react";

import { Button, Icon, IconButton } from "@mui/material";

import dictionary from "./index.dictionary.json";

export default class Content extends Component {
	state = {
		screen: window.innerWidth >= 1200 ? "large" : window.innerWidth >= 450 ? "medium" : "small",
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		if (window.innerWidth >= 1200) {
			if (this.state.screen !== "large") {
				this.setState({ screen: "large" });
			}
		} else if (window.innerWidth >= 450) {
			if (this.state.screen !== "medium") {
				this.setState({ screen: "medium" });
			}
		} else {
			if (this.state.screen !== "small") {
				this.setState({ screen: "small" });
			}
		}
	};

	render() {
		const { screen } = this.state;
		const { language } = this.props;
		if (screen === "large") {
			return (
				<div className="w-100" style={{ backgroundColor: "#00A3E0" }}>
					<div className="px-3 py-2 d-flex justify-content-between align-items-center" style={{ height: "fit-content" }}>
						<div className="text-white">
							<p className="mb-0" style={{ fontSize: "1.2rem" }}>
								<span className="fw-bold">SigningDesk</span> <span style={{ color: "#1d252d" }}>|</span> {dictionary.qualifiedDigitalSignatures[language]}
							</p>
						</div>
						<div className="d-flex align-items-center flex-nowrap">
							<Button className="mx-2 px-5" variant="contained" style={{ backgroundColor: "white", color: "#00A3E0" }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
								{dictionary.startNowForFree[language]}
							</Button>
							<IconButton className="mx-2" onClick={this.props.onClose}>
								<Icon>close</Icon>
							</IconButton>
						</div>
					</div>
				</div>
			);
		} else if (screen === "medium") {
			return (
				<div className="w-100" style={{ backgroundColor: "#00A3E0" }}>
					<div className="container py-2 d-flex justify-content-between align-items-center flex-wrap" style={{ height: "fit-content" }}>
						<p className="mb-0 fw-bold text-white" style={{ fontSize: "1.2rem" }}>
							SigningDesk
						</p>
						<Button variant="contained" style={{ backgroundColor: "white", color: "#00A3E0" }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
							{dictionary.startNowForFree[language]}
						</Button>
						<IconButton onClick={this.props.onClose}>
							<Icon style={{ color: "white" }}>close</Icon>
						</IconButton>
					</div>
				</div>
			);
		}
		return (
			<div className="w-100" style={{ backgroundColor: "#00A3E0" }}>
				<div className="container py-2 d-flex justify-content-between align-items-center flex-wrap" style={{ height: "fit-content" }}>
					<p className="mb-0 fw-bold text-white" style={{ fontSize: "1.2rem" }}>
						SigningDesk
					</p>
					<Button variant="contained" style={{ backgroundColor: "white", color: "#00A3E0" }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
						{dictionary.startNow[language]}
					</Button>
					<IconButton onClick={this.props.onClose}>
						<Icon style={{ color: "white" }}>close</Icon>
					</IconButton>
				</div>
			</div>
		);
	}
}
