import React, { Component, Fragment } from "react";

import { Button } from "@mui/material";

import dictionary from "./index.dictionary.json";

export default class Buttons extends Component {
	render() {
		const { language, bigScreen, transparent } = this.props;
		return (
			<Fragment>
				<Button
					color="inherit"
					variant="contained"
					className="mx-3"
					style={{
						fontWeight: "unset",
						transition: "all 500ms",
						...(bigScreen && transparent ? { backgroundColor: "rgba(255, 255, 255, 0.05)", border: "2px solid white" } : { backgroundColor: "rgba(255, 255, 255, 1)", border: "2px solid #00A3E0", color: "#00A3E0" }),
					}}
					onClick={this.props.onOpenContactForm}
				>
					{dictionary.contactMe[language]}
				</Button>
				<Button
					color="inherit"
					variant="contained"
					className="mx-3"
					style={{
						fontWeight: "unset",
						transition: "all 500ms",
						...(bigScreen && transparent ? { backgroundColor: "rgba(255, 255, 255, 0.05)", border: "2px solid white" } : { backgroundColor: "rgba(255, 255, 255, 1)", border: "2px solid #00A3E0", color: "#00A3E0" }),
					}}
					onClick={() => window.open(`${window.configs.appUrl}/signIn`, "_blank")}
				>
					{dictionary.logIn[language]}
				</Button>
				<Button
					color="inherit"
					variant="contained"
					className="mx-3"
					style={{ fontWeight: "unset", backgroundColor: "#00A3E0", transition: "all 500ms", color: "white" }}
					onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}
				>
					{dictionary.freeTrial[language]}
				</Button>
			</Fragment>
		);
	}
}
