import React, { Component } from "react";

import { HashLink } from "react-router-hash-link";

import Img1 from "../../../assets/imagens/signingdesk_features_withbg_new.png";

export default class FeaturesBlock2 extends Component {
	render() {
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto">
					<h5 className="mb-3 text-center m-auto" style={{ maxWidth: 800 }}>
						SigningDesk is a Signature platform developed by DigitalSign, built to guarantee five main requirements of our customers on electronic signatures:
					</h5>
					<p className="text-center" style={{ color: "#33647e", fontSize: "1.25rem" }}>
						<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Integration">
							Integration
						</HashLink>{" "}
						|{" "}
						<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Usability">
							Usability
						</HashLink>{" "}
						|{" "}
						<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Security">
							Security
						</HashLink>{" "}
						|{" "}
						<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Confidentiality">
							Confidentiality
						</HashLink>{" "}
						|{" "}
						<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Customization">
							Customization
						</HashLink>
					</p>
					<div className="d-flex justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
						<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto">
				<h5 className="mb-3 text-center m-auto" style={{ maxWidth: 800 }}>
					O SigningDesk é uma plataforma de gestão documental e de assinatura eletrónica desenvolvida pela DigitalSign, criada para garantir cinco requisitos principais de nossos clientes em assinaturas eletrónicas:
				</h5>
				<p className="text-center" style={{ color: "#33647e", fontSize: "1.25rem" }}>
					<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Integration">
						Integração
					</HashLink>{" "}
					|{" "}
					<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Usability">
						Usabilidade
					</HashLink>{" "}
					|{" "}
					<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Security">
						Segurança
					</HashLink>{" "}
					|{" "}
					<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Confidentiality">
						Confidencialidade
					</HashLink>{" "}
					|{" "}
					<HashLink className="fw-bold" style={{ color: "inherit", textDecoration: "none" }} smooth to="#Customization">
						Customização
					</HashLink>
				</p>
				<div className="d-flex justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
					<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
				</div>
			</div>
		);
	}
}
