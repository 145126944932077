import React, { Component } from "react";

import { Slide } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_integrateoverview.fdfca42a.png";

export default class OverviewBlock6 extends Component {
	state = {
		img1In: false,
	};

	render() {
		const { img1In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row">
						<div className="col-12 col-lg-4 d-flex order-1 order-lg-2 d-flex align-items-center">
							<div className="text-start">
								<h2 className="mb-4" style={{ color: "#00A3E0" }}>
									INTEGRATION API
								</h2>
								<p className="mb-4">Available as a software product for on premise deployment as well as a public or private cloud service.</p>
								<p className="mb-4">Integrate and connect SigningDesk with the systems and tools already in use.</p>
							</div>
						</div>
						<div className="col-12 col-lg-8 order-2 order-lg-1 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
							{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
							<Slide direction="up" in={img1In} timeout={1000}>
								<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
							</Slide>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row">
					<div className="col-12 col-lg-4 d-flex order-1 order-lg-2 d-flex align-items-center">
						<div className="text-start">
							<h2 className="mb-4" style={{ color: "#00A3E0" }}>
								API PARA INTEGRAÇÃO
							</h2>
							<p className="mb-4">Disponível como um produto de software para ser implementado localmente (on premises), bem como um serviço de nuvem pública ou privada.</p>
							<p className="mb-4">Integre e conecte o SigningDesk aos sistemas e ferramentas já em uso.</p>
						</div>
					</div>
					<div className="col-12 col-lg-8 order-2 order-lg-1 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
						{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
						<Slide direction="up" in={img1In} timeout={1000}>
							<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
						</Slide>
					</div>
				</div>
			</div>
		);
	}
}
