import React, { Component, Fragment } from "react";

import OverviewBlock1 from "../../components/Overview/Block1";
import OverviewBlock2 from "../../components/Overview/Block2";
import OverviewBlock3 from "../../components/Overview/Block3";
import OverviewBlock4 from "../../components/Overview/Block4";
import OverviewBlock5 from "../../components/Overview/Block5";
import OverviewBlock6 from "../../components/Overview/Block6";
import OverviewBlock7 from "../../components/Overview/Block7";
import Footer from "../../components/Footer";

export default class Overview extends Component {
	render() {
		const { language } = this.props;
		return (
			<Fragment>
				<div className="w-100 h-100" style={{ paddingTop: 64 }}>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<OverviewBlock1 language={language} />
					</div>
					<div className="w-100 py-5">
						<OverviewBlock2 language={language} />
					</div>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<OverviewBlock3 language={language} />
					</div>
					<div className="w-100 py-5">
						<OverviewBlock4 language={language} />
					</div>
					<div className="w-100 d-flex py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<OverviewBlock5 language={language} />
					</div>
					<div className="w-100 d-flex py-5">
						<OverviewBlock6 language={language} />
					</div>
					<div className="w-100 d-flex py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<OverviewBlock7 language={language} />
					</div>
					<Footer from="overview" language={language} />
				</div>
			</Fragment>
		);
	}
}
