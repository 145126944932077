import React, { Component } from "react";

import { Button, ButtonGroup } from "@mui/material";

export default class LanguageMenu extends Component {
	render() {
		const { language } = this.props;
		return (
			<ButtonGroup size="small" color="inherit" variant="text" className="ms-3" style={{ fontWeight: "unset" }}>
				<Button style={{ color: language === "pt" ? "#00A3E0" : undefined }} onClick={() => this.props.onUpdateLanguage("pt")}>
					Pt
				</Button>
				<Button style={{ color: language === "en" ? "#00A3E0" : undefined }} onClick={() => this.props.onUpdateLanguage("en")}>
					En
				</Button>
			</ButtonGroup>
		);
	}
}
