import React, { Component, Fragment } from "react";

import { Collapse, Typography } from "@mui/material";

import Package from "./Package";

import dictionary from "./index.dictionary.json";

export default class Packages extends Component {
	sortByPrice = (a, b) => {
		return a.price - b.price;
	};

	render() {
		const { plans, packages, asPlans, corporateConfigs, language } = this.props;
		const packagesArray = Object.values(packages).sort(this.sortByPrice);
		if (asPlans) {
			let sortedPlans = Object.values(plans).sort(this.sortByPrice);
			const plansGroups = [];
			for (const plan of sortedPlans) {
				const planGroup = plansGroups.find((planGroup) => planGroup[0].title === plan.title);
				if (planGroup) {
					planGroup.push(plan);
				} else {
					plansGroups.push([plan]);
				}
			}
			return (
				<Fragment>
					<div className="w-100 container mb-2">
						<Typography className="fw-bold" variant="body1">
							{dictionary.note[language]}
						</Typography>
					</div>
					<div className="w-100 d-flex justify-content-center flex-wrap">
						{plansGroups.map((plansGroup, index) => (
							<Collapse key={index} in mountOnEnter unmountOnExit appear timeout={500} orientation="horizontal">
								<Package packagesGroup={plansGroup} packagesArray={packagesArray} asPlan corporateConfigs={corporateConfigs} language={language} onBuy={this.props.onBuy} />
							</Collapse>
						))}
						<Collapse in mountOnEnter unmountOnExit appear timeout={500} orientation="horizontal">
							<Package packagesGroup={[{}]} customized asPlan corporateConfigs={corporateConfigs} language={language} onBuy={this.props.onBuy} />
						</Collapse>
					</div>
					<div className="w-100 container">
						<Typography variant="body1">{dictionary.note1[language]}</Typography>
						<Typography variant="body1">
							{dictionary.note2[language]}
							<a href={`https://www.digitalsign.pt/${language === "pt" ? "pt" : "en"}/certificado-digital/certificado-qualificado-cloud`} target="_blank" rel="noreferrer">
								{dictionary.here[language]}
							</a>
							).
						</Typography>
						<Typography variant="body1">{dictionary.note3[language]}</Typography>
						<Typography variant="body1">{dictionary.note4[language]}</Typography>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div className="w-100 container mb-2">
						<Typography className="fw-bold" variant="body1">
							{dictionary.note[language]}
						</Typography>
					</div>
					<div className="w-100 d-flex justify-content-center flex-wrap">
						{packagesArray.map((package_) => (
							<Collapse key={package_.id} in mountOnEnter unmountOnExit appear timeout={500} orientation="horizontal">
								<Package packagesGroup={[package_]} corporateConfigs={corporateConfigs} language={language} onBuy={this.props.onBuy} />
							</Collapse>
						))}
					</div>
					<div className="w-100 container">
						<Typography variant="body1">{dictionary.note1[language]}</Typography>
						<Typography variant="body1">{dictionary.note2[language]}</Typography>
						<Typography variant="body1">{dictionary.note3[language]}</Typography>
						<Typography variant="body1">{dictionary.note4[language]}</Typography>
					</div>
				</Fragment>
			);
		}
	}
}
