import React, { Component } from "react";

export default class OverviewBlock1 extends Component {
	render() {
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="w-100 text-center pt-2">
					<h2 className="mb-2" style={{ color: "#00A3E0" }}>
						SIGNINGDESK OVERVIEW
					</h2>
				</div>
			);
		}
		return (
			<div className="w-100 text-center pt-2">
				<h2 className="mb-2" style={{ color: "#00A3E0" }}>
					VISÃO GERAL DO SIGNINGDESK
				</h2>
			</div>
		);
	}
}
