import React, { Component } from "react";

import { Collapse, Divider } from "@mui/material";
import { Waypoint } from "react-waypoint";

export default class FeaturesBlock3 extends Component {
	state = {
		start: false,
		anim1In: false,
		anim2In: false,
		anim3In: false,
		anim4In: false,
		anim5In: false,
	};

	componentDidMount() {
		setTimeout(() => this.setState({ start: true }), 500);
	}

	render() {
		const { start, anim1In, anim2In, anim3In, anim4In, anim5In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto">
					<Collapse in={anim1In} timeout={1000} mountOnEnter>
						<div id="Integration" className="row my-5">
							<div className="col-12 col-md-4 d-flex mb-5">
								<div className="m-auto" style={{ color: "#00A3E0" }}>
									<h1 className="text-center mb-3">1</h1>
									<h4 className="text-uppercase">INTEGRATION</h4>
								</div>
							</div>
							<div className="col-12 col-md-8 d-flex">
								<div className="my-auto">
									<p>Easy integration with customers current systems via API/webservices.</p>
								</div>
							</div>
						</div>
					</Collapse>
					<Divider />
					<Collapse in={anim2In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim1In: true })}>
						<div id="Usability" className="row my-5">
							<div className="col-12 col-md-4 d-flex mb-5">
								<div className="m-auto" style={{ color: "#00A3E0" }}>
									<h1 className="text-center mb-3">2</h1>
									<h4 className="text-uppercase">USABILITY</h4>
								</div>
							</div>
							<div className="col-12 col-md-8 d-flex">
								<div className="my-auto">
									<p>The use of digital signatures usually brings a layer of difficulty for the user.</p>
									<p>With our integrated system for remote Digital Certificates, and for other types of electronic signatures, this layer is removed and the user experience is frictionless.</p>
								</div>
							</div>
						</div>
					</Collapse>
					<Divider />
					<Collapse in={anim3In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim2In: true })}>
						<div id="Security" className="row my-5">
							<div className="col-12 col-md-4 d-flex mb-5">
								<div className="m-auto" style={{ color: "#00A3E0" }}>
									<h1 className="text-center mb-3">3</h1>
									<h4 className="text-uppercase">SECURITY</h4>
								</div>
							</div>
							<div className="col-12 col-md-8 d-flex">
								<div className="my-auto">
									<p>All communications are carried out with several layers of security.</p>
								</div>
							</div>
						</div>
					</Collapse>
					<Divider />
					<Collapse in={anim4In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim3In: true })}>
						<div id="Confidentiality" className="row my-5">
							<div className="col-12 col-md-4 d-flex mb-5">
								<div className="m-auto" style={{ color: "#00A3E0" }}>
									<h1 className="text-center mb-3">4</h1>
									<h4 className="text-uppercase">CONFIDENTIALITY</h4>
								</div>
							</div>
							<div className="col-12 col-md-8 d-flex">
								<div className="my-auto">
									<p>
										SigningDesk can be installed in the customer's Data Center, so you are completely comfortable with the location of the documents. In case you use the Cloud solution it´s always in an EU datacenter and total
										compliance with RGPD.
									</p>
								</div>
							</div>
						</div>
					</Collapse>
					<Divider />
					<Collapse in={anim5In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim4In: true })}>
						<div id="Customization" className="row my-5">
							<div className="col-12 col-md-4 d-flex mb-5">
								<div className="m-auto" style={{ color: "#00A3E0" }}>
									<h1 className="text-center mb-3">5</h1>
									<h4 className="text-uppercase">CUSTOMIZATION</h4>
								</div>
							</div>
							<div className="col-12 col-md-8 d-flex">
								<div className="my-auto">
									<p>The platform allows changing the workflow and specific resources to better integrate with the customer's needs.</p>
									<p>The SigningDesk can be fully integrated with customer applications, including User Login, and work locally. This customized version is called corporate.</p>
									<p>For SME's that want immediate use and without initial investment, we offer the Cloud solution, standardized and with all the main functionalities.</p>
									<p>
										SigningDesk is also integrated with the Cloud Batch Signature (CBS) that allows you to sign a large number of documents in batch using a Qualified Digital certificate - specially designed for signing electronic
										invoices fulfilling the new requirements of Decree-Law No. 28/2019.
									</p>
								</div>
							</div>
						</div>
					</Collapse>
					{start && !anim5In && <Waypoint bottomOffset={220} onEnter={() => this.setState({ anim5In: true })} />}
				</div>
			);
		}
		return (
			<div className="container mx-auto">
				<Collapse in={anim1In} timeout={1000} mountOnEnter>
					<div id="Integration" className="row my-5">
						<div className="col-12 col-md-4 d-flex mb-5">
							<div className="m-auto" style={{ color: "#00A3E0" }}>
								<h1 className="text-center mb-3">1</h1>
								<h4 className="text-uppercase">INTEGRAÇÃO</h4>
							</div>
						</div>
						<div className="col-12 col-md-8 d-flex">
							<div className="my-auto">
								<p>Fácil integração com os sistemas atuais dos clientes via API / serviços da web.</p>
							</div>
						</div>
					</div>
				</Collapse>
				<Divider />
				<Collapse in={anim2In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim1In: true })}>
					<div id="Usability" className="row my-5">
						<div className="col-12 col-md-4 d-flex mb-5">
							<div className="m-auto" style={{ color: "#00A3E0" }}>
								<h1 className="text-center mb-3">2</h1>
								<h4 className="text-uppercase">USABILIDADE</h4>
							</div>
						</div>
						<div className="col-12 col-md-8 d-flex">
							<div className="my-auto">
								<p>Tradicionalmente, o uso de assinaturas digitais traz uma camada de dificuldade adicional para o utilizador.</p>
								<p>Com o nosso sistema integrado para Certificados Digitais em Cloud, tal como para outros tipos assinaturas eletrónicas simples, essa camada é removida e a experiência do utilizador é isenta de atritos.</p>
							</div>
						</div>
					</div>
				</Collapse>
				<Divider />
				<Collapse in={anim3In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim2In: true })}>
					<div id="Security" className="row my-5">
						<div className="col-12 col-md-4 d-flex mb-5">
							<div className="m-auto" style={{ color: "#00A3E0" }}>
								<h1 className="text-center mb-3">3</h1>
								<h4 className="text-uppercase">SEGURANÇA</h4>
							</div>
						</div>
						<div className="col-12 col-md-8 d-flex">
							<div className="my-auto">
								<p>Todas as comunicações são realizadas com várias camadas de segurança.</p>
							</div>
						</div>
					</div>
				</Collapse>
				<Divider />
				<Collapse in={anim4In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim3In: true })}>
					<div id="Confidentiality" className="row my-5">
						<div className="col-12 col-md-4 d-flex mb-5">
							<div className="m-auto" style={{ color: "#00A3E0" }}>
								<h1 className="text-center mb-3">4</h1>
								<h4 className="text-uppercase">CONFIDENCIALIDADE</h4>
							</div>
						</div>
						<div className="col-12 col-md-8 d-flex">
							<div className="my-auto">
								<p>
									O SigningDesk pode ser instalado no Data Center do cliente, para que você fique totalmente à vontade com a localização dos seus documentos. Caso você opte pela solução cloud, esta estará sempre disponível num
									datacenter da EU e sempre de acordo com o RGPD.
								</p>
							</div>
						</div>
					</div>
				</Collapse>
				<Divider />
				<Collapse in={anim5In} timeout={1000} mountOnEnter onEntered={() => this.setState({ anim4In: true })}>
					<div id="Customization" className="row my-5">
						<div className="col-12 col-md-4 d-flex mb-5">
							<div className="m-auto" style={{ color: "#00A3E0" }}>
								<h1 className="text-center mb-3">5</h1>
								<h4 className="text-uppercase">CUSTOMIZAÇÃO</h4>
							</div>
						</div>
						<div className="col-12 col-md-8 d-flex">
							<div className="my-auto">
								<p>A plataforma permite alterar o fluxo de trabalho e recursos específicos para se integrar melhor às necessidades do cliente.</p>
								<p>O SigningDesk pode ser totalmente integrado com as aplicações dos clientes, incluindo Login dos utilizadores, e funcionar localmente. Sendo esta versão customizada designada de corporate.</p>
								<p>Para PME´s que queiram uma utilização imediata e sem investimento inicial oferecemos a solução Cloud, padronizada e com todas as principais funcionalidades.</p>
								<p>
									O SigningDesk está ainda integrado com o Cloud Batch Signature (CBS) que lhe permite assinar em lote um elevado número de documentos, com recurso a um certificado Digital Qualificado - especialmente concebido para
									a assinatura de faturas eletrónicas cumprindo os novos requisitos do Decreto-Lei nº 28/2019.
								</p>
							</div>
						</div>
					</div>
				</Collapse>
				{start && !anim5In && <Waypoint bottomOffset={220} onEnter={() => this.setState({ anim5In: true })} />}
			</div>
		);
	}
}
