import React, { Component } from "react";

import { Collapse, Zoom } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_userexperience.png";
import Img2 from "../../../assets/imagens/signingdesk_fast.png";
import Img3 from "../../../assets/imagens/ce_lock_no_bg.png";
import Img4 from "../../../assets/imagens/signingdesk_costreduction.png";

export default class BenefitsBlock2 extends Component {
	state = {
		img1In: false,
		show2: false,
		anim1In: false,
		img2In: false,
		show3: false,
		anim2In: false,
		img3In: false,
		show4: false,
		anim3In: false,
		img4In: false,
		anim4In: false,
	};

	render() {
		const { img1In, show2, anim1In, img2In, show3, anim2In, img3In, show4, anim3In, img4In, anim4In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto">
					<div className="d-flex flex-wrap justify-content-center">
						<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
							{!img1In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img1In: true })} />}
							<Zoom in={img1In} timeout={500} onEntered={() => this.setState({ anim1In: true, show2: true })}>
								<div>
									<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
										<img className="m-auto" src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
									</div>
									<Collapse in={anim1In}>
										<div className="text-center">
											<h3 className="mb-3">USER EXPERIENCE</h3>
											<p>The digital signature is fast, sustainable, secure and extremely convenient, contributing to a better experience for everyone.</p>
										</div>
									</Collapse>
								</div>
							</Zoom>
						</div>
						<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
							{show2 && !img2In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img2In: true })} />}
							<Zoom in={img2In} timeout={500} onEntered={() => this.setState({ anim2In: true, show3: true })}>
								<div>
									<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
										<img className="m-auto" src={Img2} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
									</div>
									<Collapse in={anim2In}>
										<div className="text-center">
											<h3 className="mb-3">FAST & SECURE</h3>
											<p>Send and sign documents securely anywhere using any device. Close agreements much faster on this new contactless world.</p>
										</div>
									</Collapse>
								</div>
							</Zoom>
						</div>
						<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
							{show3 && !img3In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img3In: true })} />}
							<Zoom in={img3In} timeout={500} onEntered={() => this.setState({ anim3In: true, show4: true })}>
								<div>
									<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
										<img className="m-auto" src={Img3} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
									</div>
									<Collapse in={anim3In}>
										<div className="text-center">
											<h3 className="mb-3">LEGAL VALIDITY</h3>
											<p>
												DigitalSign is a European Qualified Trust Service Provider, guaranteeing security and legal validity for the entire signature process. See{" "}
												<a href="https://webgate.ec.europa.eu/tl-browser/#/tl/PT/6" target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
													here
												</a>{" "}
												our accreditation at the European Commission.
											</p>
										</div>
									</Collapse>
								</div>
							</Zoom>
						</div>
						<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
							{show4 && !img4In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img4In: true })} />}
							<Zoom in={img4In} timeout={500} onEntered={() => this.setState({ anim4In: true })}>
								<div>
									<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
										<img className="m-auto" src={Img4} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
									</div>
									<Collapse in={anim4In}>
										<div className="text-center">
											<h3 className="mb-3">MONEY SAVING</h3>
											<p>eSignature saves you a lot of money on printing and courier/Post office. Both capital and operational expenditure significantly reduced.</p>
										</div>
									</Collapse>
								</div>
							</Zoom>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto">
				<div className="d-flex flex-wrap justify-content-center">
					<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
						{!img1In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img1In: true })} />}
						<Zoom in={img1In} timeout={500} onEntered={() => this.setState({ anim1In: true, show2: true })}>
							<div>
								<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
									<img className="m-auto" src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
								</div>
								<Collapse in={anim1In}>
									<div className="text-center">
										<h3 className="mb-3">EXPERIÊNCIA DE UTILIZADOR</h3>
										<p>A assinatura digital é rápida, sustentável, segura e extremamente conveniente, contribuindo para uma melhor experiência para todos.</p>
									</div>
								</Collapse>
							</div>
						</Zoom>
					</div>
					<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
						{show2 && !img2In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img2In: true })} />}
						<Zoom in={img2In} timeout={500} onEntered={() => this.setState({ anim2In: true, show3: true })}>
							<div>
								<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
									<img className="m-auto" src={Img2} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
								</div>
								<Collapse in={anim2In}>
									<div className="text-center">
										<h3 className="mb-3">RÁPIDA E SEGURA</h3>
										<p>Envie e assine documentos com segurança em qualquer lugar através de qualquer dispositivo. Feche acordos de forma mais rápida neste novo mundo em que o digital será privilegiado.</p>
									</div>
								</Collapse>
							</div>
						</Zoom>
					</div>
					<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
						{show3 && !img3In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img3In: true })} />}
						<Zoom in={img3In} timeout={500} onEntered={() => this.setState({ anim3In: true, show4: true })}>
							<div>
								<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
									<img className="m-auto" src={Img3} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
								</div>
								<Collapse in={anim3In}>
									<div className="text-center">
										<h3 className="mb-3">VALIDADE LEGAL</h3>
										<p>
											DigitalSign é um prestador qualificado de serviços de confiança acreditado ao nível Europeu, garantindo segurança e validade jurídica para todos os processos de assinatura. Veja{" "}
											<a href="https://webgate.ec.europa.eu/tl-browser/#/tl/PT/6" target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
												aqui
											</a>{" "}
											a nossa acreditação na Comissão Europeia.
										</p>
									</div>
								</Collapse>
							</div>
						</Zoom>
					</div>
					<div className="d-flex justify-content-center my-5" style={{ width: 300 }}>
						{show4 && !img4In && <Waypoint bottomOffset={100} onEnter={() => this.setState({ img4In: true })} />}
						<Zoom in={img4In} timeout={500} onEntered={() => this.setState({ anim4In: true })}>
							<div>
								<div className="w-100 d-flex mb-5" style={{ width: 100, height: 100 }}>
									<img className="m-auto" src={Img4} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
								</div>
								<Collapse in={anim4In}>
									<div className="text-center">
										<h3 className="mb-3">REDUÇÃO DE CUSTOS</h3>
										<p>A assinatura eletrónica economiza muito dinheiro em impressão e correio. As despesas de capital e operacionais reduzirão significativamente.</p>
									</div>
								</Collapse>
							</div>
						</Zoom>
					</div>
				</div>
			</div>
		);
	}
}
