import React from "react";
import ReactDOM from "react-dom";

import 'react-notifications/lib/notifications.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import App from "./containers/App";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
