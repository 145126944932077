import React, { Component } from "react";

import { Dialog, Slide, Paper } from "@mui/material";
import Draggable from "react-draggable";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide ref={ref} {...props} direction="up" timeout={500} />;
});

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export default class MyModal extends Component {
	state = {
		isLargeScreen: window.innerWidth >= 664,
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		if (window.innerWidth >= 664) {
			if (!this.state.isLargeScreen) {
				this.setState({ isLargeScreen: true });
			}
		} else {
			if (this.state.isLargeScreen) {
				this.setState({ isLargeScreen: false });
			}
		}
	};

	render() {
		const { isLargeScreen } = this.state;
		return (
			<Dialog TransitionComponent={Transition} fullScreen={this.props.fullScreen || !isLargeScreen} PaperComponent={this.props.fullScreen || !isLargeScreen ? undefined : PaperComponent} aria-labelledby="draggable-dialog-title" {...this.props}>
				{this.props.children}
			</Dialog>
		);
	}
}
