import React, { Component, Fragment } from "react";

import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, Fade, FormControlLabel, Icon, IconButton, TextField } from "@mui/material";
import Axios from "axios";
import { NotificationManager } from "react-notifications";

import dictionary from "./index.dictionary.json";

export default class ContactForm extends Component {
	state = {
		name: { value: "", error: true, touched: false },
		company: { value: "", error: true, touched: false },
		email: { value: "", error: true, touched: false },
		phoneNumber: { value: "", error: true, touched: false },
		description: { value: dictionary.acquisitionOfQualifiedCertificate[this.props.language], error: false, touched: false },
		certProfile: { value: "", error: true },
		certQuantity: { value: "", error: true },
		agreeWith: false,
		formLoading: false,
	};

	submitForm = async () => {
		const { name, company, email, phoneNumber, description, certProfile, certQuantity } = this.state;
		const { language } = this.props;
		const payload = {
			name: name.value,
			company: company.value,
			email: email.value,
			phoneNumber: phoneNumber.value,
			subject: "SigningDesk Contact Form",
			description: [
				certProfile.value ? `Perfil do certificado: ${dictionary[certProfile.value].pt}` : null,
				certQuantity.value ? `Quantidade de certificados: ${dictionary[certQuantity.value].pt}` : null,
				`${(certProfile.value || certQuantity.value) && "\n"}${description.value}`,
			]
				.filter((el) => el)
				.join("\n"),
		};
		this.setState({ formLoading: true });
		try {
			const resp = await Axios.post(`${window.configs.coreUrl}/api/v2/helpers/sendEcomEmail`, payload);
			if (resp && resp.data && resp.data.statusCode === 200) {
				this.setState({
					name: { value: "", error: true, touched: false },
					company: { value: "", error: true, touched: false },
					email: { value: "", error: true, touched: false },
					phoneNumber: { value: "", error: true, touched: false },
					description: { value: dictionary.acquisitionOfQualifiedCertificate[language], error: false, touched: false },
					certProfile: { value: "", error: true },
					certQuantity: { value: "", error: true },
					agreeWith: false,
					formLoading: false,
				});
				NotificationManager.success(dictionary.ticketCreated[language], "", 20000);
				this.props.onClose();
				return;
			}
		} catch (error) {}
		NotificationManager.error(dictionary.somethingWentWrong[language]);
		this.setState({ formLoading: false });
	};

	render() {
		const { name, company, email, phoneNumber, description, certProfile, certQuantity, agreeWith, formLoading } = this.state;
		const { language } = this.props;
		return (
			<Fragment>
				<DialogTitle style={{ color: "#00A3E0" }}>
					<div className="w-100 d-flex justify-content-between align-items-center">
						<span className="me-3">{dictionary.letsTalk[language]}</span>
						<IconButton size="small" disabled={formLoading} onClick={this.props.onClose}>
							<Icon>close</Icon>
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent dividers style={{ color: "#33647E" }}>
					<div className="row" style={{ display: "contents" }}>
						<TextField
							value={name.value}
							error={name.error && name.touched}
							label={dictionary.name[language]}
							required
							className="mb-3 px-1 col-12 col-sm-6"
							onChange={({ target: { value } }) => this.setState({ name: { value, error: !Boolean(value), touched: true } })}
						/>
						<TextField
							value={company.value}
							error={company.error && company.touched}
							label={dictionary.company[language]}
							required
							className="mb-3 px-1 col-12 col-sm-6"
							onChange={({ target: { value } }) => this.setState({ company: { value, error: !Boolean(value), touched: true } })}
						/>
					</div>
					<div className="row" style={{ display: "contents" }}>
						<TextField
							value={email.value}
							error={email.error && email.touched}
							label={dictionary.email[language]}
							required
							className="mb-3 px-1 col-12 col-sm-6"
							onChange={({ target: { value } }) => this.setState({ email: { value, error: !Boolean(value), touched: true } })}
						/>
						<TextField
							value={phoneNumber.value}
							error={phoneNumber.error && phoneNumber.touched}
							label={dictionary.phoneNumber[language]}
							required
							className="mb-3 px-1 col-12 col-sm-6"
							onChange={({ target: { value } }) => this.setState({ phoneNumber: { value, error: !Boolean(value), touched: true } })}
						/>
					</div>
					<TextField
						value={description.value}
						error={description.error && description.touched}
						multiline
						rows={2}
						fullWidth
						label={dictionary.pleaseDescribe[language]}
						required
						className="mb-4"
						onChange={({ target: { value } }) => this.setState({ description: { value, error: !Boolean(value), touched: true } })}
					/>
					<p className="mb-3">{dictionary.forABetterDiagnosis[language]}</p>
					<div className="px-3">
						<p>{dictionary.whatIsTheDesiredCertificateProfile[language]}</p>
						{["individual", "professional", "member", "representation", "dontKnowNeedHelp"].map((label) => (
							<FormControlLabel
								key={label}
								label={dictionary[label][language]}
								className="mb-0"
								control={<Checkbox checked={certProfile.value === label} onChange={({ target: { checked } }) => this.setState({ certProfile: checked ? { value: label, error: false } : { value: "", error: true } })} color="primary" />}
							/>
						))}
						<p className="mt-3">{dictionary.howManyCertificatesDoYouDesire[language]}</p>
						{["1-10", "11-25", ">25", "dontKnow"].map((label, index) => (
							<FormControlLabel
								key={label}
								label={dictionary[label][language]}
								className="mb-0"
								control={
									<Checkbox checked={certQuantity.value === label} onChange={({ target: { checked } }) => this.setState({ certQuantity: checked ? { value: label, error: false } : { value: "", error: true } })} color="primary" />
								}
							/>
						))}
					</div>
				</DialogContent>
				<DialogActions>
					<div className="w-100 d-flex flex-wrap flex-sm-nowrap justify-content-center">
						<FormControlLabel
							label={
								<span>
									{dictionary.iAgreeToThe[language]}{" "}
									<a href="https://www.digitalsign.pt/media/files/Downloads/privacy-policy-digitalsign.pdf" target="_blank" rel="noreferrer">
										{dictionary.privacyPolicy[language]}
									</a>{" "}
									{dictionary.andAuthorizeTheSubmission[language]}
								</span>
							}
							className="mb-0"
							control={<Checkbox checked={agreeWith} onChange={({ target: { checked } }) => this.setState({ agreeWith: checked })} color="primary" />}
						/>
						<div className="d-flex justify-content-center align-items-center position-relative">
							<Button
								disabled={formLoading || name.error || company.error || email.error || phoneNumber.error || description.error || !agreeWith}
								variant="contained"
								style={{ backgroundColor: formLoading ? undefined : "", transition: "all 500ms" }}
								onClick={this.submitForm}
							>
								{dictionary.submit[language]}
							</Button>
							<Fade in={formLoading} timeout={500} mountOnEnter unmountOnExit>
								<div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ cursor: "default", position: "absolute", top: 0, left: 0 }}>
									<CircularProgress style={{ width: 36, height: 36 }} />
								</div>
							</Fade>
						</div>
					</div>
				</DialogActions>
			</Fragment>
		);
	}
}
