import Axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Collapse, Dialog, Slide } from "@mui/material";
import moment from "moment";
import "moment/locale/en-au";
import "moment/locale/pt";

import ContactForm from "../../components/ContactForm";
import MainAppBar from "../../components/MainAppBar";
import StartForFreeDrawer from "../../components/StartForFreeDrawer";
import Benefits from "./Benefits";
import Features from "./Features";
import Home from "./Home";
import Overview from "./Overview";
import Store from "./Store";

const Languages = {
	en: { id: "en", locale: "en-au" },
	pt: { id: "pt", locale: "pt" },
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class Pages extends Component {
	state = {
		language: localStorage.getItem("language") ? localStorage.getItem("language") : navigator.language ? navigator.language.substr(0, 2) : "pt",
		transparentAppBar: this.props.location.pathname === "/home",
		showStartForFreeDrawer: this.props.location.pathname !== "/home",
		plans: [],
		packages: [],
		contactFormOpenned: false,
	};

	async componentDidMount() {
		moment.locale(Languages[this.state.language].locale);
		if (!["/home", "/overview", "/features", "/benefits", "/store"].includes(this.props.location.pathname)) {
			this.props.history.push("/home");
		}
		try {
			const resps = await Promise.all([Axios.get(`${window.configs.coreUrl}/api/v2/plans`), Axios.get(`${window.configs.coreUrl}/api/v2/packages`)]);
			if (resps.every((resp) => resp && resp.data && resp.data.statusCode === 200)) {
				const { result: plans } = resps[0].data;
				const { result: packages } = resps[1].data;
				this.setState({ plans, packages });
			}
		} catch (error) {}
	}

	updateLanguage = (language) => {
		localStorage.setItem("language", language);
		moment.locale(Languages[language].locale);
		this.setState({ language });
	};

	render() {
		const { language, transparentAppBar, showStartForFreeDrawer, plans, packages, contactFormOpenned } = this.state;
		const { location } = this.props;
		return (
			<div className="w-100 h-100">
				<MainAppBar language={language} transparent={transparentAppBar} onOpenContactForm={() => this.setState({ contactFormOpenned: true })} onUpdateLanguage={this.updateLanguage} />
				<div className="w-100 h-100 d-flex">
					<Collapse in={location.pathname === "/home"} mountOnEnter unmountOnExit timeout={500} orientation="horizontal" onExit={() => this.setState({ transparentAppBar: false, showStartForFreeDrawer: true })}>
						<div style={{ width: "100vw", height: "100vh", overflow: "hidden overlay", transition: "all 460ms cubic-bezier(0.4, 0, 0.6, 1) 0ms" }}>
							<Home language={language} onSetTransparentAppBar={(transparentAppBar) => this.setState({ transparentAppBar })} onSetStartForFreeDrawer={(showStartForFreeDrawer) => this.setState({ showStartForFreeDrawer })} />
						</div>
					</Collapse>
					<Collapse in={location.pathname === "/overview"} mountOnEnter unmountOnExit timeout={500} orientation="horizontal">
						<div style={{ width: "100vw", height: "100vh", overflow: "hidden overlay", transition: "all 460ms cubic-bezier(0.4, 0, 0.6, 1) 0ms" }}>
							<Overview language={language} />
						</div>
					</Collapse>
					<Collapse in={location.pathname === "/features"} mountOnEnter unmountOnExit timeout={500} orientation="horizontal">
						<div style={{ width: "100vw", height: "100vh", overflow: "hidden overlay", transition: "all 460ms cubic-bezier(0.4, 0, 0.6, 1) 0ms" }}>
							<Features language={language} />
						</div>
					</Collapse>
					<Collapse in={location.pathname === "/benefits"} mountOnEnter unmountOnExit timeout={500} orientation="horizontal">
						<div style={{ width: "100vw", height: "100vh", overflow: "hidden overlay", transition: "all 460ms cubic-bezier(0.4, 0, 0.6, 1) 0ms" }}>
							<Benefits language={language} />
						</div>
					</Collapse>
					<Collapse in={location.pathname === "/store"} mountOnEnter unmountOnExit timeout={500} orientation="horizontal">
						<div style={{ width: "100vw", height: "100vh", overflow: "hidden overlay", transition: "all 460ms cubic-bezier(0.4, 0, 0.6, 1) 0ms" }}>
							<Store plans={plans} packages={packages} language={language} />
						</div>
					</Collapse>
				</div>
				<StartForFreeDrawer show={showStartForFreeDrawer} language={language} />
				<Dialog open={contactFormOpenned} fullWidth maxWidth="md" scroll="paper" TransitionComponent={Transition} onClose={() => this.setState({ contactFormOpenned: false })}>
					<ContactForm language={language} onClose={() => this.setState({ contactFormOpenned: false })} />
				</Dialog>
			</div>
		);
	}
}

export default withRouter(Pages);
