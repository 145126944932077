import React, { Component } from "react";

import { Typography } from "@mui/material";

export default class HomeBlock8 extends Component {
	render() {
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="w-100 container text-center my-5">
					<h3 className="mb-3">
						Try it and see how simple it is to close contracts quickly and safely!{" "}
						<a href={`${window.configs.appUrl}/signUp`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
							Free Trial
						</a>
					</h3>
					<Typography className="mb-5">Contact our sales team to help define the type of platform that best suits your needs.</Typography>
					<h2 className="mb-3" style={{ color: "#00A3E0" }}>
						TOGETHER WE ARE STRONGER!
					</h2>
				</div>
			);
		}
		return (
			<div className="w-100 container text-center my-5">
				<h3 className="mb-3">
					Experimente e veja como é simples fechar contratos de forma rápida e segura!{" "}
					<a href={`${window.configs.appUrl}/signUp`} target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
						Free Trial
					</a>
				</h3>
				<Typography className="mb-5">Contate a nossa equipa comercial, para ajudarmos a definir qual o tipo de plataforma que melhor se adequa às suas necessidades.</Typography>
				<h2 className="mb-3" style={{ color: "#00A3E0" }}>
					JUNTOS SOMOS MAIS FORTES!
				</h2>
			</div>
		);
	}
}
