import React, { Component } from "react";

import { Collapse, Slide } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_monitor1.9d3c1196.png";
import Img2 from "../../../assets/imagens/signingdesk_monitor2.b6230319.png";
import Img3 from "../../../assets/imagens/signingdesk_monitor3.91343b12.png";

export default class HomeBlock7 extends Component {
	state = {
		img1In: false,
		box1In: false,
		img2In: false,
		box2In: false,
		img3In: false,
		box3In: false,
	};

	render() {
		const { img1In, box1In, img2In, box2In, img3In, box3In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="d-flex justify-content-center flex-wrap">
						<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
							<Collapse in={box1In}>
								<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
									<div className="px-3">
										<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
											EASY TO SEND
										</h3>
										<p className="mb-4">Enter the signatories' email addresses in the order in which you want the documents to be signed.</p>
										<p className="mb-4">Enter manually or automatically the fields to collect digital signatures and other data. Click send and you're done!</p>
									</div>
								</div>
							</Collapse>
							{!img1In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img1In: true })} />}
							<Slide direction="up" in={img1In} timeout={1000} onEntered={() => this.setState({ box1In: true })}>
								<div className="w-100 d-flex justify-content-center">
									<img className="mb-3" src={Img1} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
								</div>
							</Slide>
						</div>
						<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
							<Collapse in={box2In}>
								<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
									<div className="px-3">
										<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
											EASY TO SIGN
										</h3>
										<p className="mb-4">Signatories only have to click on the link they receive by email, view the document and sign using a browser on their computer or mobile device.</p>
										<p className="mb-4">Do you need a signing certificate? No problem... You can also order it directly on the Portal.</p>
									</div>
								</div>
							</Collapse>
							{!img2In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img2In: true })} />}
							<Slide direction="up" in={img2In} timeout={1000} onEntered={() => this.setState({ box2In: true })}>
								<div className="w-100 d-flex justify-content-center">
									<img className="mb-3" src={Img2} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
								</div>
							</Slide>
						</div>
						<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
							<Collapse in={box3In}>
								<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
									<div className="px-3">
										<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
											EASY TO CONTROL
										</h3>
										<p className="mb-4">Receive notification that the document has been signed. Has the Signatory not yet signed? Notify again...</p>
										<p className="mb-4">Set deadlines for the signatures of each of the signatories. Have full control of the entire process in the palm of your hand, to the second and a click away.</p>
									</div>
								</div>
							</Collapse>
							{!img3In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img3In: true })} />}
							<Slide direction="up" in={img3In} timeout={1000} onEntered={() => this.setState({ box3In: true })}>
								<div className="w-100 d-flex justify-content-center">
									<img className="mb-3" src={Img3} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
								</div>
							</Slide>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="d-flex justify-content-center flex-wrap">
					<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
						<Collapse in={box1In}>
							<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
								<div className="px-3">
									<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
										FÁCIL DE ENVIAR
									</h3>
									<p className="mb-4">Introduza os endereços de e-mail dos signatários pela ordem segundo a qual pretende que os documentos sejam assinados.</p>
									<p className="mb-4">Insira manualmente ou de forma automática os campos para recolher assinaturas digitais e outros dados. Clique em enviar e já está!</p>
								</div>
							</div>
						</Collapse>
						{!img1In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img1In: true })} />}
						<Slide direction="up" in={img1In} timeout={1000} onEntered={() => this.setState({ box1In: true })}>
							<div className="w-100 d-flex justify-content-center">
								<img className="mb-3" src={Img1} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
							</div>
						</Slide>
					</div>
					<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
						<Collapse in={box2In}>
							<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
								<div className="px-3">
									<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
										FÁCIL DE ASSINAR
									</h3>
									<p className="mb-4">Os signatários só têm de clicar no link que recebem por email, ver o documento e assinar através de um browser no seu computador ou dispositivo móvel.</p>
									<p className="mb-4">Precisa de um certificado de assinatura? Sem problema... Também o pode pedir diretamente no Portal.</p>
								</div>
							</div>
						</Collapse>
						{!img2In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img2In: true })} />}
						<Slide direction="up" in={img2In} timeout={1000} onEntered={() => this.setState({ box2In: true })}>
							<div className="w-100 d-flex justify-content-center">
								<img className="mb-3" src={Img2} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
							</div>
						</Slide>
					</div>
					<div className="mx-3 mb-5" style={{ maxWidth: 340 }}>
						<Collapse in={box3In}>
							<div className="mb-4 text-center text-white d-flex align-items-center justify-content-center" style={{ height: 340, backgroundColor: "#1d252d" }}>
								<div className="px-3">
									<h3 className="text-center mb-4" style={{ color: "#00A3E0" }}>
										FÁCIL DE CONTROLAR
									</h3>
									<p className="mb-4">Receba uma notificação de que o documento foi assinado. O Signatário ainda não assinou? Volte a notificar...</p>
									<p className="mb-4">Defina prazos limite para as assinaturas de cada um dos signatários. Tenha o controlo integral de todo o processo na palma da mão, ao segundo e à distância de um clique.</p>
								</div>
							</div>
						</Collapse>
						{!img3In && <Waypoint bottomOffset={200} onEnter={() => this.setState({ img3In: true })} />}
						<Slide direction="up" in={img3In} timeout={1000} onEntered={() => this.setState({ box3In: true })}>
							<div className="w-100 d-flex justify-content-center">
								<img className="mb-3" src={Img3} alt="img" style={{ maxHeight: 190, maxWidth: "100%" }} />
							</div>
						</Slide>
					</div>
				</div>
			</div>
		);
	}
}
