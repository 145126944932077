import React, { Component } from "react";

import { Button, Collapse, Zoom } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Ban1 from "../../../assets/banner/DS_SD_Slider_movie-print.jpg";

import Classes from "./index.module.css";

import dictionary from "./index.dictionary.json";

export default class HomeBlock1 extends Component {
	state = {
		anim1: false,
		anim2: false,
	};

	componentDidMount() {
		setTimeout(() => this.setState({ anim1: true }), 500);
	}

	render() {
		const { anim1, anim2 } = this.state;
		const { language } = this.props;
		return (
			<div className="w-100 h-100" style={{ position: "relative" }}>
				<div className="w-100 h-100">
					<img src={Ban1} alt="img" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
				</div>
				<div className="w-100 h-100 d-flex" style={{ position: "absolute", top: 0, left: 0, paddingTop: 128, backgroundColor: "rgba(51, 100, 126, 0.7)" }}>
					<div className="m-auto text-center" style={{ color: "white" }}>
						<Waypoint topOffset={80} onEnter={() => this.props.onSetTransparentAppBar(true)} onLeave={() => this.props.onSetTransparentAppBar(false)} />
						<h1 className="mb-3" style={{ fontWeight: "bold" }}>
							SigningDesk
						</h1>
						<h5 className="mb-5">{dictionary.qualifiedDigitalSignatures[language]}</h5>
						<Waypoint topOffset={60} onEnter={() => this.props.onSetStartForFreeDrawer(false)} onLeave={() => this.props.onSetStartForFreeDrawer(true)} />
						<Zoom in={anim1} timeout={1000} onEntered={() => this.setState({ anim2: true })}>
							<Button size="large" color="inherit" variant="contained" className="px-3 px-sm-5 mx-5 mb-3" style={{ backgroundColor: "#00A3E0", fontSize: 22 }} onClick={() => window.open(`${window.configs.appUrl}/signUp`, "_blank")}>
								{dictionary.startNowForFree[language]}
							</Button>
						</Zoom>
						<Collapse in={anim2} appear timeout={1000}>
							<div className="m-auto" style={{ width: 200, height: 200 }}>
								<p className={Classes.Arrow}>
									<a href="/#" style={{ pointerEvents: "none" }}>
										{" "}
									</a>
								</p>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	}
}
