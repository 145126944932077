import React, { Component } from "react";

import YouTube from "react-youtube";

export default class HomeBlock3 extends Component {
	state = {
		height: window.innerHeight,
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		this.setState({ height: window.innerHeight });
	};

	render() {
		const { height } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="w-100 text-center my-5">
					<h4 className="mb-4" style={{ color: "#00A3E0" }}>
						MEET THE SIGNINGDESK
					</h4>
					<div className="mb-4 w-100" style={{ height: "65vh" }}>
						<YouTube videoId="cBol1I74mWQ" opts={{ width: "100%", height: 0.65 * height }} />
					</div>
				</div>
			);
		}
		return (
			<div className="w-100 text-center my-5">
				<h4 className="mb-4" style={{ color: "#00A3E0" }}>
					CONHEÇA O SIGNINGDESK
				</h4>
				<div className="mb-4 w-100" style={{ height: "65vh" }}>
					<YouTube videoId="WGOBGL1e7F8" opts={{ width: "100%", height: 0.65 * height }} />
				</div>
			</div>
		);
	}
}
