import React, { Component, Fragment } from "react";

import dictionary from "./Package.dictionary.json";

export default class PackageTooltip extends Component {
	render() {
		const { package_, hideSup, language } = this.props;
		return (
			<Fragment>
				{Boolean(package_.users) && <li>{`${package_.users > 0 ? package_.users : dictionary.unlimited[language]} ${dictionary[package_.users !== 1 ? "users" : "user"][language]}`}</li>}
				{Boolean(package_.companies) && <li>{`${package_.companies > 0 ? package_.companies : dictionary.unlimited[language]} ${dictionary[package_.companies !== 1 ? "companies" : "company"][language]}`}</li>}
				{Boolean(package_.customization) && <li>{dictionary.customization[language]}</li>}
				{Boolean(package_.qualified) && (
					<li>
						{dictionary.qualified[language]} {!hideSup && <sup>2</sup>}
					</li>
				)}
				{Boolean(package_.advanced) && <li>{dictionary.advanced[language]}</li>}
				{Boolean(package_.platform) && <li>{dictionary.platform[language]}</li>}
				{Boolean(package_.manual) && <li>{dictionary.manual[language]}</li>}
				{Boolean(package_.citizenCard) && <li>{dictionary.citizenCard[language]}</li>}
				{Boolean(package_.trainingHours) && <li>{`${package_.trainingHours > 0 ? package_.trainingHours : dictionary.unlimited[language]} ${dictionary[package_.trainingHours !== 1 ? "trainingHours" : "trainingHour"][language]}`}</li>}
				{Boolean(package_.workflows) && <li>{`${package_.workflows > 0 ? package_.workflows : dictionary.unlimited[language]} ${dictionary[package_.workflows !== 1 ? "workflows" : "workflow"][language]}`}</li>}
				{Boolean(package_.documentsPerWorkflow) && (
					<li>{`${package_.documentsPerWorkflow > 0 ? package_.documentsPerWorkflow : dictionary.unlimited[language]} ${dictionary[package_.documentsPerWorkflow !== 1 ? "documentsPerWorkflow" : "documentPerWorkflow"][language]}`}</li>
				)}
				{Boolean(package_.groupsPerWorkflow) && (
					<li>{`${package_.groupsPerWorkflow > 0 ? package_.groupsPerWorkflow : dictionary.unlimited[language]} ${dictionary[package_.groupsPerWorkflow !== 1 ? "groupsPerWorkflow" : "groupPerWorkflow"][language]}`}</li>
				)}
				{Boolean(package_.collaboratorsPerGroup) && (
					<li>{`${package_.collaboratorsPerGroup > 0 ? package_.collaboratorsPerGroup : dictionary.unlimited[language]} ${dictionary[package_.collaboratorsPerGroup !== 1 ? "collaboratorsPerGroup" : "collaboratorPerGroup"][language]}`}</li>
				)}
				{Boolean(package_.myDocuments) && <li>{`${package_.myDocuments > 0 ? package_.myDocuments : dictionary.unlimited[language]} ${dictionary[package_.myDocuments !== 1 ? "myDocuments" : "myDocument"][language]}`}</li>}
				{Boolean(package_.documents) && <li>{`${package_.documents > 0 ? package_.documents : dictionary.unlimited[language]} ${dictionary[package_.documents !== 1 ? "documents" : "document"][language]}`}</li>}
				{Boolean(package_.signatures) && (
					<li>
						{`${package_.signatures > 0 ? package_.signatures : dictionary.unlimited[language]} ${dictionary[package_.signatures !== 1 ? "signatures" : "signature"][language]}`} {!hideSup && <sup>3</sup>}
					</li>
				)}
				{Boolean(package_.workflowsTemplates) && (
					<li>{`${package_.workflowsTemplates > 0 ? package_.workflowsTemplates : dictionary.unlimited[language]} ${dictionary[package_.workflowsTemplates !== 1 ? "workflowsTemplates" : "workflowTemplate"][language]}`}</li>
				)}
				{package_.features.map((feature, index) => (
					<li key={index}>{feature.text[language]}</li>
				))}
			</Fragment>
		);
	}
}
