import React, { useCallback, useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Icon, Slide, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Waypoint } from "react-waypoint";

import iconCheck from "../../../assets/icons/check.png";
import iconGB from "../../../assets/icons/gb_flag.png";
import iconPT from "../../../assets/icons/pt_flag.png";
import iconWrong from "../../../assets/icons/wrong.png";
import Img1 from "../../../assets/imagens/signingdesk_private.b84f8807.jpg";
import Img2 from "../../../assets/imagens/signingdesk_shared.35ffed8d.jpg";

import dictionary from "./index.dictionary.json";

const featuresList = [
	//Legal Compliance
	{
		title: "legalCompliance",
		details: [
			{
				text: "legalComplianceText1",
				standard: true,
				corporate: true,
			},
		],
	},
	//Workflow
	{
		title: "workflow",
		details: [
			{
				text: "workflowText1",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText2",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText3",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText4",
				standard: true,
				corporate: false,
			},
			{
				text: "workflowText5",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText6",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText7",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText8",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText9",
				standard: true,
				corporate: true,
			},
			{
				text: "workflowText10",
				standard: false,
				corporate: true,
			},
			{
				text: "workflowText11",
				standard: false,
				corporate: true,
			},
			{
				text: "workflowText12",
				standard: false,
				corporate: true,
			},
			{
				text: "workflowText13",
				standard: false,
				corporate: true,
			},
		],
	},
	//Documento
	{
		title: "document",
		details: [
			{
				text: "documentText1",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText2",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText3",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText4",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText5",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText6",
				standard: true,
				corporate: true,
			},
			{
				text: "documentText7",
				standard: false,
				corporate: true,
			},
			{
				text: "documentText8",
				standard: false,
				corporate: true,
			},
			{
				text: "documentText9",
				standard: false,
				corporate: true,
			},
			{
				text: "documentText10",
				standard: "30 MB",
				corporate: "30 MB",
			},
		],
	},
	//Management
	{
		title: "management",
		details: [
			{
				text: "managementText1",
				standard: false,
				corporate: true,
			},
			{
				text: "managementText2",
				standard: false,
				corporate: true,
			},
			{
				text: "managementText3",
				standard: true,
				corporate: true,
			},
			{
				text: "managementText4",
				standard: true,
				corporate: true,
			},
			{
				text: "managementText5",
				standard: false,
				corporate: true,
			},
			{
				text: "managementText6",
				standard: false,
				corporate: true,
			},
			{
				text: "managementText7",
				standard: 10,
				corporate: 10,
			},
			{
				text: "managementText8",
				standard: "flags",
				corporate: "flags",
			},
		],
	},
	//Integração
	{
		title: "integration",
		details: [
			{
				text: "integrationText1",
				standard: false,
				corporate: true,
			},
			{
				text: "integrationText2",
				standard: false,
				corporate: true,
			},
		],
	},
	//Extras
	{
		title: "extras",
		details: [
			{
				text: "extrasText1",
				standard: true,
				corporate: true,
			},
			{
				text: "extrasText2",
				standard: true,
				corporate: true,
			},
			{
				text: "extrasText3",
				standard: true,
				corporate: true,
			},
			{
				text: "extrasText4",
				standard: true,
				corporate: true,
			},
			{
				text: "extrasText5",
				standard: true,
				corporate: true,
			},
			{
				text: "extrasText6",
				standard: false,
				corporate: true,
			},
			{
				text: "extrasText7",
				standard: false,
				corporate: true,
			},
			{
				text: "extrasText8",
				standard: false,
				corporate: true,
			},
		],
	},
];

const screenToPx = {
	large: 32,
	medium: 28,
	small: 24,
};

export default function HomeBlock6({ language }) {
	const [screen, setScreen] = useState(window.innerWidth >= 560 ? "large" : window.innerWidth >= 350 ? "medium" : "small");
	const [img1In, setImg1In] = useState(false);
	const [img2In, setImg2In] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const windowResize = useCallback(() => {
		if (window.innerWidth >= 560) {
			if (screen !== "large") {
				setScreen("large");
			}
		} else if (window.innerWidth >= 350) {
			if (screen !== "medium") {
				setScreen("medium");
			}
		} else {
			if (screen !== "small") {
				setScreen("small");
			}
		}
	}, [screen]);

	useEffect(() => {
		window.addEventListener("resize", windowResize);
	}, [windowResize]);

	useEffect(() => {
		window.addEventListener("resize", windowResize);
	}, [windowResize]);

	return (
		<div className="container mx-auto my-5">
			<div>
				<h2 className="mb-4 text-center" style={{ fontSize: screenToPx[screen], color: "#00A3E0" }}>
					{dictionary.productOptionsTitle[language]}
				</h2>
				<h5 className="mb-5 text-center m-auto" style={{ maxWidth: 800 }}>
					{dictionary.productOptionsText[language]}
				</h5>
			</div>
			<div className="row mb-5">
				<div className="col-12 col-lg-6 d-flex order-1 order-lg-2 d-flex align-items-center">
					<div className="text-start">
						<h2 className="mb-4 position-relative">
							<div style={{ position: "absolute", top: -16, left: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>
							{dictionary.signingDeskCloudPlatformTitle[language]} | <span style={{ color: "#00A3E0" }}>{dictionary.private[language]}</span>
						</h2>
						<p className="mb-4">
							{dictionary.signingDeskPrivatePlatformText1[language]}
							<span style={{ color: "#00A3E0" }}>{dictionary.signingDeskPrivatePlatformText1Span[language]}</span>
							{dictionary.signingDeskPrivatePlatformText2[language]}
						</p>
						<p className="mb-4">{dictionary.signingDeskPrivatePlatformText3[language]}</p>
						<p className="mb-4">{dictionary.signingDeskPrivatePlatformText4[language]}</p>
					</div>
				</div>
				<div className="col-12 col-lg-6 order-2 order-lg-1 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
					{!img1In && <Waypoint bottomOffset={32} onEnter={() => setImg1In(true)} />}
					<Slide direction="up" in={img1In} timeout={1000}>
						<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
					</Slide>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
					<div className="text-start">
						<h2 className="mb-4 position-relative">
							<div style={{ position: "absolute", top: -16, left: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>
							{dictionary.signingDeskCloudPlatformTitle[language]} | <span style={{ color: "#00A3E0" }}>{dictionary.shared[language]}</span>
						</h2>
						<p className="mb-4">{dictionary.signingDeskSharedPlatformText1[language]}</p>
						<p className="mb-4">{dictionary.signingDeskSharedPlatformText2[language]}</p>
						<p className="mb-4">{dictionary.signingDeskSharedPlatformText3[language]}</p>
					</div>
				</div>
				<div className="col-12 col-lg-6 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
					{!img2In && <Waypoint bottomOffset={32} onEnter={() => setImg2In(true)} />}
					<Slide direction="up" in={img2In} timeout={1000}>
						<img src={Img2} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
					</Slide>
				</div>
			</div>
			<div className="row">
				<div className="col-12 text-center">
					<h2 className="mb-4" style={{ fontSize: screenToPx[screen], color: "#00A3E0" }}>
						{dictionary.featuresListTitle[language]}
					</h2>
					{featuresList.map((el, index) => {
						return (
							<Accordion key={index} expanded={expanded === index} sx={{ backgroundColor: grey[200] }} className="mb-2" onClick={() => setExpanded(expanded === index ? false : index)}>
								<AccordionSummary expandIcon={<Icon color="#00A3E0">expand_more</Icon>}>
									<Typography variant="h6" color="#00A3E0">
										{dictionary[el.title][language]}
									</Typography>
								</AccordionSummary>
								<AccordionDetails sx={{ backgroundColor: grey[50] }}>
									<div className="d-flex justify-content-end mb-2 text-center">
										<div className="w-50 d-flex">
											<Typography className="w-50 fw-bold">{dictionary.standardVersion[language]}</Typography>
											<Typography className="w-50 fw-bold" color="#00A3E0">
												{dictionary.corporateVersion[language]}
											</Typography>
										</div>
									</div>
									{el.details.map((el, index) => {
										return (
											<div key={index} className="w-100 text-start d-flex align-items-center mb-3">
												<Typography variant="subtitle1" className="w-50">
													{dictionary[el.text][language]}
												</Typography>
												<div className="w-50 d-flex align-items-center">
													<div className="w-50 d-flex justify-content-center align-items-center">
														{el.standard === "30 MB" ? (
															<Typography>30 MB</Typography>
														) : el.standard === 10 ? (
															<Typography>10</Typography>
														) : el.standard === "flags" ? (
															<div className="d-flex">
																<img src={iconPT} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
																<img src={iconGB} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
															</div>
														) : el.standard ? (
															<img src={iconCheck} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
														) : (
															<img src={iconWrong} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
														)}
													</div>
													<div className="w-50 d-flex justify-content-center align-items-center">
														{el.corporate === "30 MB" ? (
															<Typography>30 MB</Typography>
														) : el.corporate === 10 ? (
															<Typography>10</Typography>
														) : el.corporate === "flags" ? (
															<div className="d-flex">
																<img src={iconPT} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
																<img src={iconGB} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
															</div>
														) : el.corporate ? (
															<img src={iconCheck} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
														) : (
															<img src={iconWrong} alt="img" style={{ maxHeight: 32, pointerEvents: "none" }} />
														)}
													</div>
												</div>
											</div>
										);
									})}
								</AccordionDetails>
							</Accordion>
						);
					})}
				</div>
			</div>
		</div>
	);
}
