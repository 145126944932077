import React, { Component, Fragment } from "react";

import { Button } from "@mui/material";
import { YouTube, Facebook, LinkedIn } from "@mui/icons-material";

import Img01 from "../../assets/parceiros/logo_santander.png";
import Img02 from "../../assets/parceiros/logo_jeronimomartins.png";
import Img03 from "../../assets/parceiros/logo_ordemadvogados.png";
import Img04 from "../../assets/parceiros/logo_itecons.png";
import Img05 from "../../assets/parceiros/logo_universalis.png";
import Img06 from "../../assets/parceiros/logo_cp.png";
import Img07 from "../../assets/parceiros/logo_vodafone.png";
import Img08 from "../../assets/parceiros/logo_repsol.png";
import Img09 from "../../assets/parceiros/logo_keysign.png";
import Img10 from "../../assets/parceiros/logo_cmbarreiro.png";
import Img11 from "../../assets/parceiros/logo_cmoeiras.png";
import Img12 from "../../assets/parceiros/logo_cmvizela.png";
import Img13 from "../../assets/parceiros/logo_cmcoimbra.png";
import Img14 from "../../assets/parceiros/logo_swissLife.png";
import Img15 from "../../assets/parceiros/logo_difbroker.png";
import Img16 from "../../assets/parceiros/logo_bancobni.png";
import Img17 from "../../assets/parceiros/logo_autoridadeconcorrencia.png";
import Img18 from "../../assets/parceiros/logo_reta.png";
import Img19 from "../../assets/parceiros/logo_sonaecapital.png";
import Img20 from "../../assets/parceiros/logo_emel.png";
import Img21 from "../../assets/parceiros/logo_municipiogondomar.png";
import Img22 from "../../assets/parceiros/logo_meo.png";
import Img23 from "../../assets/parceiros/logo_edp.png";
import Img24 from "../../assets/parceiros/logo_cgd.png";
import Img25 from "../../assets/parceiros/logo_scania.png";
import Img26 from "../../assets/parceiros/logo_universidadeporto.png";
import Img27 from "../../assets/parceiros/logo_borgstena.png";
import Img28 from "../../assets/parceiros/logo_pampilhosaserra.png";
import Img29 from "../../assets/parceiros/logo_montemorvelho.png";
import Img30 from "../../assets/parceiros/logo_recheio.png";
import Img31 from "../../assets/parceiros/logo_cmmaia.png";
import Img32 from "../../assets/parceiros/logo_inatel.png";
import Img33 from "../../assets/parceiros/logo_bestbank.png";
import Img34 from "../../assets/parceiros/logo_aeroportosportugal.png";
import Img35 from "../../assets/parceiros/logo_tribunalcontas.png";
import Img36 from "../../assets/parceiros/logo_zoomarine.png";
import Img37 from "../../assets/parceiros/logo_cerealis.png";
import Img38 from "../../assets/parceiros/logo_onsynergy.png";
import Img39 from "../../assets/parceiros/logo_aguasportugal.png";
import Img40 from "../../assets/parceiros/logo_logifarma.png";
import Img41 from "../../assets/parceiros/logo_pfizer.png";
import Img42 from "../../assets/parceiros/logo_motaengil.png";

import Logo from "../../assets/logos/Logo_DS_SigningDesk_desktop.svg";
import Img43 from "../../assets/imagens/eidas-logo.png";
import Img44 from "../../assets/imagens/qualified-t-provider.png";

import Classes from "./index.module.css";

import dictionary from "./index.dictionary.json";

export default class Footer extends Component {
	state = {
		rotating: true,
		rotateClients1Timeout: null,
		rotateClients2Timeout: null,
		imgs1: [Img01, Img02, Img03, Img04, Img05, Img06, Img07, Img08, Img09, Img10, Img11, Img12, Img13, Img14, Img15, Img16, Img17, Img18, Img19, Img20, Img21],
		imgs2: [Img22, Img23, Img24, Img25, Img26, Img27, Img28, Img29, Img30, Img31, Img32, Img33, Img34, Img35, Img36, Img37, Img38, Img39, Img40, Img41, Img42],
	};

	componentDidMount() {
		this.rotateClients1();
		this.rotateClients2();
	}

	componentWillUnmount() {
		clearTimeout(this.state.rotateClients1Timeout);
		clearTimeout(this.state.rotateClients2Timeout);
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	rotateClients1 = () => {
		const { rotating, imgs1: imgs1Dump } = this.state;
		if (rotating) {
			document.querySelector(`#clients-list-1${this.props.from}`).style.transition = "all 3s ease 0s";
			document.querySelector(`#clients-list-1${this.props.from}`).style.transform = "translateX(-220px)";
			this.setState({
				rotateClients1Timeout: setTimeout(async () => {
					const imgs1 = [...imgs1Dump.slice(1), ...imgs1Dump.slice(0, 1)];
					document.querySelector(`#clients-list-1${this.props.from}`).style.transition = null;
					document.querySelector(`#clients-list-1${this.props.from}`).style.transform = "translateX(0px)";
					this.setState({ imgs1 });
					await this.sleep(200);
					this.rotateClients1();
				}, 3000),
			});
		}
	};

	rotateClients2 = () => {
		const { rotating, imgs2: imgs2Dump } = this.state;
		if (rotating) {
			document.querySelector(`#clients-list-2${this.props.from}`).style.transition = "all 3s ease 0s";
			document.querySelector(`#clients-list-2${this.props.from}`).style.transform = "translateX(-220px)";
			this.setState({
				rotateClients2Timeout: setTimeout(async () => {
					const imgs2 = [...imgs2Dump.slice(1), ...imgs2Dump.slice(0, 1)];
					document.querySelector(`#clients-list-2${this.props.from}`).style.transition = null;
					document.querySelector(`#clients-list-2${this.props.from}`).style.transform = "translateX(0px)";
					this.setState({ imgs2 });
					await this.sleep(200);
					this.rotateClients2();
				}, 3000),
			});
		}
	};

	render() {
		const { imgs1, imgs2 } = this.state;
		const { language } = this.props;
		return (
			<Fragment>
				<div className="w-100 py-5">
					<div className="w-100 my-5">
						<div className={["mb-5", Classes.Clients].join(" ")}>
							<div className={Classes.ClientsWrap}>
								<ul id={`clients-list-1${this.props.from}`} className="p-0 m-0" style={{ width: imgs1.length * 220 }}>
									{imgs1.map((path) => (
										<li key={path}>
											<img src={path} alt="img" />
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className={["mb-5", Classes.Clients].join(" ")}>
							<div className={Classes.ClientsWrap}>
								<ul id={`clients-list-2${this.props.from}`} className="p-0 m-0" style={{ width: imgs2.length * 220 }}>
									{imgs2.map((path) => (
										<li key={path}>
											<img src={path} alt="img" />
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="w-100 py-5" style={{ backgroundColor: "#00A3E0" }}>
					<div className="container my-5">
						<div className="mb-4 d-flex">
							<img className="m-auto m-md-0" src={Logo} alt="logo" />
						</div>
						<div className="d-flex justify-content-around justify-content-xl-between align-items-center flex-wrap">
							<div className="my-4">
								<p className="mb-0">{`${dictionary.address[language]}:`}</p>
								<p className="text-white">Largo Pe. Bernardino Ribeiro Fernandes, 26</p>
								<p className="mb-0">{`${dictionary.phone[language]}:`}</p>
								<p className="text-white">+351 253 560 650/651<br /><small>{dictionary.phoneMessage[language]}</small></p>
								<p className="mb-0">{`${dictionary.commercial[language]}:`}</p>
								<p className="text-white">comercial@digitalsign.pt</p>
							</div>
							<div className="my-4">
								<div className="d-flex justify-content-center flex-wrap">
									<div className="m-2" style={{ height: 70 }}>
										<img src={Img44} alt="logo" style={{ maxHeight: "100%" }} />
									</div>
									<div className="m-2" style={{ height: 70 }}>
										<img src={Img44} alt="logo" style={{ maxHeight: "100%" }} />
									</div>
								</div>
								<div className="d-flex justify-content-center flex-wrap">
									<div className="m-2" style={{ height: 70 }}>
										<img src={Img43} alt="logo" style={{ maxHeight: "100%" }} />
									</div>
								</div>
							</div>
							<div className="my-4 text-white">
								<div className="mb-2 d-flex justify-content-between">
									<YouTube style={{ fontSize: "3rem" }} />
									<Facebook style={{ fontSize: "3rem" }} />
									<LinkedIn style={{ fontSize: "3rem" }} />
								</div>
								<div className="d-flex justify-content-around flex-nowrap">
									<Button color="inherit">{dictionary.support[language]}</Button>
									<Button color="inherit" onClick={() => window.open(language === "en" ? "https://www.digitalsign.pt/en/informationcenter/faq" : "https://www.digitalsign.pt/informationcenter/faq", "_blank")}>
										{dictionary.faqs[language]}
									</Button>
								</div>
							</div>
						</div>
						<div className="text-center">
							<p>{dictionary.copyrights[language]}</p>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
