import React, { Component, Fragment } from "react";

import HomeBlock1 from "../../components/Home/Block1";
import HomeBlock2 from "../../components/Home/Block2";
import HomeBlock3 from "../../components/Home/Block3";
import HomeBlock4 from "../../components/Home/Block4";
import HomeBlock5 from "../../components/Home/Block5";
import HomeBlock6 from "../../components/Home/Block6";
import HomeBlock7 from "../../components/Home/Block7";
import HomeBlock8 from "../../components/Home/Block8";
import Footer from "../../components/Footer";

export default class Home extends Component {
	render() {
		const { language } = this.props;
		return (
			<Fragment>
				<div className="w-100 h-100">
					<div className="w-100 h-100">
						<HomeBlock1 language={language} onSetTransparentAppBar={this.props.onSetTransparentAppBar} onSetStartForFreeDrawer={this.props.onSetStartForFreeDrawer} />
					</div>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<HomeBlock2 language={language} />
					</div>
					<div className="w-100 py-5">
						<HomeBlock3 language={language} />
					</div>
					<div className="w-100 d-flex py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<HomeBlock4 language={language} />
					</div>
					<div className="w-100 d-flex py-5">
						<HomeBlock5 language={language} />
					</div>
					<div className="w-100 d-flex py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<HomeBlock6 language={language} />
					</div>
					<div className="w-100 d-flex py-5">
						<HomeBlock7 language={language} />
					</div>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<HomeBlock8 language={language} />
					</div>
					<Footer from="home" language={language} />
				</div>
			</Fragment>
		);
	}
}
