import React, { Component, Fragment } from "react";

import { Paper, Chip, Collapse, Icon, List, ListItem, ListItemText, Typography, Divider, Button, Zoom, Tab, Tabs, IconButton, ListItemButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { green, red, blueGrey } from "@mui/material/colors";
import moment from "moment";

import MyModal from "../../Generic/MyModal";
import PackageForm from "./PackageForm";

import core from "../../../api/core";

import sleep from "../../../helpers/sleep";
import formatBytes from "../../../helpers/formatBytes";

import dictionary from "./Package.dictionary.json";
import PackageTooltip from "./PackageTooltip";

export default class Package extends Component {
	state = {
		anim1: false,
		anim2: false,
		packageFormOpenned: false,
		formLoading: false,
		selectedPackageIndex: 0,
		adicionalPackages: [],
		anchorEl: null,
	};

	async componentDidMount() {
		await sleep(500);
		this.setState({ anim1: true });
		await sleep(1000);
		this.setState({ anim2: true });
	}

	submitForm = async (payload) => {
		this.setState({ formLoading: true });
		const resp = await core.post(`/helpers/sendEcomEmail`, payload);
		this.setState({ formLoading: false });
		return resp;
	};

	render() {
		const { anim1, anim2, packageFormOpenned, formLoading, selectedPackageIndex, adicionalPackages, anchorEl } = this.state;
		const { packagesGroup, packagesArray, customized, asPlan, corporateConfigs, language } = this.props;
		const package_ = packagesGroup[selectedPackageIndex];
		return (
			<Paper elevation={5} className="m-3" style={{ minWidth: 200, maxWidth: 300, height: "fit-content", overflow: "unset" }}>
				<div className="w-100 p-2" style={{ backgroundColor: corporateConfigs.color, color: "white", position: "relative", transition: "background-color 500ms" }}>
					{package_.new ? (
						<Zoom in={anim2} mountOnEnter unmountOnExit appear timeout={500}>
							<div style={{ position: "absolute", top: 0, right: -18 }}>
								<Chip
									style={{ backgroundColor: "white", color: corporateConfigs.color, border: `2px solid ${corporateConfigs.color}`, transform: "rotate(45deg)", transition: "color 500ms" }}
									size="small"
									label={dictionary.new[language]}
									icon={<Icon style={{ color: "inherit", fontSize: "1.2rem" }}>new_releases</Icon>}
								/>
							</div>
						</Zoom>
					) : null}
					{package_.mostPurchased ? (
						<Zoom in={anim2} mountOnEnter unmountOnExit appear timeout={500}>
							<div style={{ position: "absolute", top: -18, left: "calc(50% - 121.89px / 2)" }}>
								<Chip
									style={{ backgroundColor: "white", color: corporateConfigs.color, border: `2px solid ${corporateConfigs.color}`, transition: "color 500ms" }}
									size="small"
									label={dictionary.mostPurchased[language]}
									icon={<Icon style={{ color: "inherit", fontSize: "1.2rem" }}>shop_two</Icon>}
								/>
							</div>
						</Zoom>
					) : null}
					{package_.mostAffordable ? (
						<Zoom in={anim2} mountOnEnter unmountOnExit appear timeout={500}>
							<div style={{ position: "absolute", top: 9, left: -28 }}>
								<Chip
									style={{ backgroundColor: "white", color: corporateConfigs.color, border: `2px solid ${corporateConfigs.color}`, transform: "rotate(-45deg)", transition: "color 500ms" }}
									size="small"
									label={dictionary.mostAffordable[language]}
									icon={<Icon style={{ color: "inherit", fontSize: "1.2rem" }}>euro</Icon>}
								/>
							</div>
						</Zoom>
					) : null}
					<Typography variant="h6" align="center">
						{!customized ? package_.title : dictionary.customizedPlan[language]}
					</Typography>
				</div>

				<Collapse in={anim1} appear timeout={500}>
					{!customized ? (
						<Fragment>
							{package_.id !== "455fdbc2-ee57-4fb4-955f-1b04b5fe4a11" ? (
								<Typography className="mt-2 mb-0" variant="h6" align="center">
									{`${parseFloat((package_.price + adicionalPackages.reduce((sum, current) => sum + current.price, 0)) / 1.23).toFixed(2)}€`} <sup>*</sup>
								</Typography>
							) : (
								<Typography className="mt-2 mb-0" variant="h6" align="center">
									{dictionary.free[language]}
								</Typography>
							)}
							{asPlan && (
								<Typography className="mb-2 mt-0" variant="body1" align="center">
									{`${dictionary.validFor[language]}  ${moment.duration(package_.duration, "seconds").humanize()}`}
								</Typography>
							)}
						</Fragment>
					) : (
						<Typography className="my-2" variant="h6" align="center">
							{dictionary.requestQuotation[language]}
						</Typography>
					)}
					{packagesGroup.length > 1 && (
						<Tabs value={selectedPackageIndex} variant="fullWidth" centered sx={{ minHeight: "unset" }} onChange={(_, selectedPackageIndex) => this.setState({ selectedPackageIndex })}>
							{packagesGroup.map((_, index) => (
								<Tab key={index} className="py-2" sx={{ minHeight: 0 }} label={index + 1} value={index} />
							))}
						</Tabs>
					)}
					<List dense className="py-0">
						<Collapse in={package_.users !== 0 && package_.users !== undefined} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={package_.users === -1 ? dictionary.unlimitedUsers[language] : `${package_.users} ${dictionary[package_.users > 1 ? "users" : "user"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.companies > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.companies} ${dictionary[package_.companies > 1 ? "companies" : "company"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.storage > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${formatBytes(package_.storage)} ${dictionary.storage[language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.customization)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={dictionary.customization[language]} />
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.qualified)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText
									primary={
										<span>
											{dictionary.qualified[language]} <sup>**</sup>
										</span>
									}
								/>
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.advanced)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={<span>{dictionary.advanced[language]}</span>} />
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.platform)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={dictionary.platform[language]} />
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.manual)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={dictionary.manual[language]} />
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.citizenCard)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={dictionary.citizenCard[language]} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.trainingHours > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={<span>{`${package_.trainingHours} ${dictionary[package_.trainingHours > 1 ? "trainingHours" : "trainingHour"][language]}`} <sup>***</sup></span>} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.workflows !== 0 && package_.workflows !== undefined} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={package_.workflows === -1 ? dictionary.unlimitedWorkflows[language] : `${package_.workflows} ${dictionary[package_.workflows > 1 ? "workflows" : "workflow"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.documentsPerWorkflow > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.documentsPerWorkflow} ${dictionary[package_.documentsPerWorkflow > 1 ? "documentsPerWorkflow" : "documentPerWorkflow"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.groupsPerWorkflow > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.groupsPerWorkflow} ${dictionary[package_.groupsPerWorkflow > 1 ? "groupsPerWorkflow" : "groupPerWorkflow"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.collaboratorsPerGroup > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.collaboratorsPerGroup} ${dictionary[package_.collaboratorsPerGroup > 1 ? "collaboratorsPerGroup" : "collaboratorPerGroup"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.myDocuments > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.myDocuments} ${dictionary[package_.myDocuments > 1 ? "myDocuments" : "myDocument"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.documents > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText primary={`${package_.documents} ${dictionary[package_.documents > 1 ? "documents" : "document"][language]}`} />
							</ListItem>
						</Collapse>
						<Collapse in={package_.signatures > 0} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText
									primary={
										<span>
											{`${package_.signatures} ${dictionary[package_.signatures > 1 ? "signatures" : "signature"][language]}`} <sup>****</sup>
										</span>
									}
								/>
							</ListItem>
						</Collapse>
						<Collapse in={Boolean(package_.workflowsTemplates !== 0 && package_.workflowsTemplates !== undefined)} timeout={500}>
							<ListItem style={{ color: green[500] }}>
								<Icon className="me-2">check_circle_outline</Icon>
								<ListItemText
									primary={
										package_.workflowsTemplates === -1
											? dictionary.unlimitedWorkflowsTemplates[language]
											: `${package_.workflowsTemplates} ${dictionary[package_.workflowsTemplates > 1 ? "workflowsTemplates" : "workflowTemplate"][language]}`
									}
								/>
							</ListItem>
						</Collapse>
						{package_.features?.map((feature, index) => (
							<ListItem key={index} style={{ color: feature.available !== false ? green[500] : red[500] }}>
								<Icon className="me-2">{feature.available !== false ? "check_circle_outline" : "highlight_off"}</Icon>
								<ListItemText primary={feature.text[language]} />
							</ListItem>
						))}
					</List>
					<Divider />
					{asPlan && !customized && package_.id !== "455fdbc2-ee57-4fb4-955f-1b04b5fe4a11" && (
						<div className="container py-3">
							<Typography variant="body1" className="mb-2">
								{dictionary.addAPackage[language]}
							</Typography>
							{adicionalPackages.map((aditionalPackage, index) => (
								<Tooltip key={index} title={<PackageTooltip package_={aditionalPackage} language={language} />} arrow placement="right">
									<ListItem
										dense
										secondaryAction={
											<IconButton edge="end" onClick={() => this.setState({ adicionalPackages: adicionalPackages.filter((thisAdicionalPackage) => thisAdicionalPackage !== aditionalPackage) })}>
												<Icon>delete</Icon>
											</IconButton>
										}
									>
										<ListItemText>{`${aditionalPackage.title} - ${dictionary.for[language]} ${parseFloat(aditionalPackage.price / 1.23).toFixed(2)}€`}</ListItemText>
									</ListItem>
								</Tooltip>
							))}
							<ListItemButton onClick={({ currentTarget: anchorEl }) => this.setState({ anchorEl })}>
								<Icon className="m-auto">add</Icon>
							</ListItemButton>
							<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => this.setState({ anchorEl: null })}>
								{packagesArray.map((aditionalPackage) => (
									<Tooltip key={aditionalPackage.id} title={<PackageTooltip package_={aditionalPackage} language={language} />} arrow placement="right">
										<MenuItem onClick={() => this.setState({ adicionalPackages: [...adicionalPackages, aditionalPackage], anchorEl: null })}>{`${aditionalPackage.title} - ${dictionary.for[language]} ${parseFloat(
											aditionalPackage.price / 1.23
										).toFixed(2)}€`}</MenuItem>
									</Tooltip>
								))}
							</Menu>
						</div>
					)}
					<Divider />
					{package_.id !== "455fdbc2-ee57-4fb4-955f-1b04b5fe4a11" && (
						<div className="w-100 py-3 d-flex justify-content-center" style={{ backgroundColor: blueGrey[50] }}>
							{!customized ? (
								<Button
									variant="contained"
									className="text-white text-capitalize"
									style={{ backgroundColor: corporateConfigs.color, transition: "background-color 500ms" }}
									onClick={() => this.props.onBuy(asPlan ? { planId: package_.id, packagesIds: adicionalPackages.map(({ id }) => id) } : { packagesIds: [package_.id] })}
								>
									{dictionary.buyNow[language]}
								</Button>
							) : (
								<Button variant="contained" className="text-white text-capitalize" style={{ backgroundColor: corporateConfigs.color, transition: "background-color 500ms" }} onClick={() => this.setState({ packageFormOpenned: true })}>
									{dictionary.contactUs[language]}
								</Button>
							)}
						</div>
					)}
				</Collapse>
				<MyModal open={packageFormOpenned} fullWidth maxWidth="md" scroll="paper" onClose={formLoading ? undefined : () => this.setState({ packageFormOpenned: false })}>
					<PackageForm formLoading={formLoading} corporateConfigs={corporateConfigs} language={language} onSubmitForm={this.submitForm} onClose={() => this.setState({ packageFormOpenned: false })} />
				</MyModal>
			</Paper>
		);
	}
}
