import React, { Component } from "react";

import { Slide } from "@mui/material";
import { Waypoint } from "react-waypoint";

import Img1 from "../../../assets/imagens/signingdesk_legalvalidityoverview.27e8d49d.png";

export default class OverviewBlock7 extends Component {
	state = {
		img1In: false,
	};

	render() {
		const { img1In } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row">
						<div className="col-12 col-lg-4 d-flex d-flex align-items-center">
							<div className="text-start">
								<h2 className="mb-4" style={{ color: "#00A3E0" }}>
									LAWFUL AND COURT-ADMISSIBLE
								</h2>
								<p className="mb-4">Complete solution for qualified / advance / simples and biometric electronic signatures, document approval and status tracking.</p>
								<p className="mb-4">All our services comply with EU eIDAS Regulation. We automatically generate and store a robust audit trail for every agreement.</p>
								<p className="mb-4">
									<b>Check for our accreditation at the EU Comission</b>{" "}
									<a href="https://webgate.ec.europa.eu/tl-browser/#/tl/PT/6" target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
										here
									</a>
									.
								</p>
							</div>
						</div>
						<div className="col-12 col-lg-8 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
							{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
							<Slide direction="up" in={img1In} timeout={1000}>
								<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
							</Slide>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row">
					<div className="col-12 col-lg-4 d-flex d-flex align-items-center">
						<div className="text-start">
							<h2 className="mb-4" style={{ color: "#00A3E0" }}>
								VALIDADE LEGAL E ADMISSIBILIDADE EM TRIBUNAL JURÍDICA
							</h2>
							<p className="mb-4">Solução com plena validade legal para assinaturas eletrónicas qualificadas / avançadas / simples e biométricas, com aprovação de documentos e rastreamento de status.</p>
							<p className="mb-4">Todos os nossos serviços estão em conformidade com o Regulamento eIDAS da UE. Geramos e armazenamos automaticamente uma trilha de auditoria robusta para cada documento.</p>
							<p className="mb-4">
								<b>Verifique a nossa acreditação na Comissão Europeia</b>{" "}
								<a href="https://webgate.ec.europa.eu/tl-browser/#/tl/PT/6" target="_blank" rel="noreferrer" style={{ color: "#00A3E0", textDecoration: "none" }}>
									aqui
								</a>
								.
							</p>
						</div>
					</div>
					<div className="col-12 col-lg-8 d-flex align-items-center justify-content-center" style={{ maxHeight: "min(70vh, 500px)" }}>
						{!img1In && <Waypoint bottomOffset={32} onEnter={() => this.setState({ img1In: true })} />}
						<Slide direction="up" in={img1In} timeout={1000}>
							<img src={Img1} alt="img" style={{ maxHeight: "100%", maxWidth: "100%" }} />
						</Slide>
					</div>
				</div>
			</div>
		);
	}
}
