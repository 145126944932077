import React, { Component, Fragment } from "react";

import BenefitsBlock1 from "../../components/Benefits/Block1";
import BenefitsBlock2 from "../../components/Benefits/Block2";
import Footer from "../../components/Footer";

export default class Benefits extends Component {
	render() {
		const { language } = this.props;
		return (
			<Fragment>
				<div className="w-100 h-100" style={{ paddingTop: 64 }}>
					<div className="w-100 py-5" style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
						<BenefitsBlock1 language={language} />
					</div>
					<div className="w-100 py-5">
						<BenefitsBlock2 language={language} />
					</div>
					<Footer from="overview" language={language} />
				</div>
			</Fragment>
		);
	}
}
