import axios from "axios";

var axiosObj = axios.create({
	baseURL: window.configs.coreUrl + "/api/v2",
	timeout: 180000,
});

const obj = {
	get: async function (endPoint, data, token) {
		try {
			if (token) {
				axiosObj.defaults.headers.common["Authorization"] = "Bearer " + token;
			}
			return await axiosObj.get(endPoint, data);
		} catch (error) {
			console.log(endPoint);
			console.log(data);
			console.log(error);
			return error.response;
		}
	},
	put: async function (endPoint, data, token) {
		try {
			if (token) {
				axiosObj.defaults.headers.common["Authorization"] = "Bearer " + token;
			}
			return await axiosObj.put(endPoint, data);
		} catch (error) {
			console.log(endPoint);
			console.log(data);
			console.log(error);
			return error.response;
		}
	},
	post: async function (endPoint, data, token) {
		try {
			if (token) {
				axiosObj.defaults.headers.common["Authorization"] = "Bearer " + token;
			}
			return await axiosObj.post(endPoint, data);
		} catch (error) {
			console.log(endPoint);
			console.log(data);
			console.log(error);
			return error.response;
		}
	},
	delete: async function (endPoint, data, token) {
		try {
			if (token) {
				axiosObj.defaults.headers.common["Authorization"] = "Bearer " + token;
			}
			return await axiosObj.delete(endPoint, data);
		} catch (error) {
			console.log(endPoint);
			console.log(data);
			console.log(error);
			return error.response;
		}
	},
};

export default obj;
