import React, { Component } from "react";

import YouTube from "react-youtube";

export default class HomeBlock5 extends Component {
	state = {
		screen: window.innerWidth >= 560 ? "large" : window.innerWidth >= 350 ? "medium" : "small",
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}

	windowResize = () => {
		if (window.innerWidth >= 560) {
			if (this.state.screen !== "large") {
				this.setState({ screen: "large" });
			}
		} else if (window.innerWidth >= 350) {
			if (this.state.screen !== "medium") {
				this.setState({ screen: "medium" });
			}
		} else {
			if (this.state.screen !== "small") {
				this.setState({ screen: "small" });
			}
		}
	};

	screenToPx = {
		large: 32,
		medium: 28,
		small: 24,
	};

	render() {
		const { screen } = this.state;
		const { language } = this.props;
		if (language === "en") {
			return (
				<div className="container mx-auto my-5">
					<div className="row mb-2">
						<div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center">
							<div className="text-end">
								<h2 className="mb-4 position-relative" style={{ fontSize: this.screenToPx[screen] }}>
									<div style={{ position: "absolute", top: -16, right: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>
									We provide qualified digital certificates to the European Commission and European Agencies
								</h2>
								<p className="mb-4">
									Get to know in this video the <b>EU Sign</b> tool of the European Commission, developed in conjunction with DigitalSign.
								</p>
								<p className="mb-4">We are proud to also be the provider of Qualified Digital certificates for employees of the European Commission and several European Agencies.</p>
							</div>
						</div>
						<div className="col-12 col-lg-6 d-flex order-2 order-lg-1">
							<YouTube containerClassName="w-100 m-auto" videoId="UUGbspMyKUM" opts={{ width: "100%" }} />
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="container mx-auto my-5">
				<div className="row mb-2">
					<div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center">
						<div className="text-end">
							<h2 className="mb-4 position-relative" style={{ fontSize: this.screenToPx[screen] }}>
								<div style={{ position: "absolute", top: -16, right: -30, width: 48, height: 48, backgroundColor: "#00A3E0", zIndex: -1 }}></div>
								Fornecemos certificados digitais qualificados para a Comissão Europeia e Agências Europeias
							</h2>
							<p className="mb-4">
								Conheça neste vídeo a ferramenta <b>EU Sign</b> da Comissão Europeia, desenvolvida em conjunto com a DigitalSign.
							</p>
							<p className="mb-4">Temos orgulho de ser também o fornecedor de certificados Digitais Qualificados para os colaboradores da Comissão Europeia e de diversas Agencias Europeias.</p>
						</div>
					</div>
					<div className="col-12 col-lg-6 d-flex order-2 order-lg-1">
						<YouTube containerClassName="w-100 m-auto" videoId="UUGbspMyKUM" opts={{ width: "100%" }} />
					</div>
				</div>
			</div>
		);
	}
}
